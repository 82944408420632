import React from 'react';

import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  isAuthenticatedWithApiKey,
  isAuthenticatedWithCognito
} from 'operations/auth/credentials';
import { PAGES } from 'app';
import { isEmbeddedInDriverApp } from '@loggi/drivers-app-version';
import HomeSkeleton from 'view/molecules/home-skeleton';
import { Redirect } from 'react-router';

export default function LoadingHome() {
  const {
    state: { authenticatedUser: user, loading }
  } = useAmplifyAuth();

  const isDriverAppAuth =
    isEmbeddedInDriverApp() && isAuthenticatedWithApiKey();
  const isCognitoAuth = user && isAuthenticatedWithCognito(user);
  const notLoading = !loading;

  if (isDriverAppAuth) {
    return (
      <Redirect
        to={{
          pathname: PAGES.HOME,
          search: window.location.search
        }}
      />
    );
  }

  if (notLoading && isCognitoAuth) {
    return (
      <Redirect
        to={{
          pathname: PAGES.HOME
        }}
      />
    );
  }

  if (notLoading && (!isDriverAppAuth || !isCognitoAuth)) {
    return (
      <Redirect
        to={{
          pathname: PAGES.LOGIN
        }}
      />
    );
  }

  return <HomeSkeleton />;
}
