const featureSwitches = {
  enableResponseFromAllocationService: 'enableResponseFromAllocationService',
  directPickupThroughtDrivers: 'directPickupThroughtDrivers',
  enableDisputeDocumentValidation: 'enableDisputeDocumentValidation',
  disableToLeveGoogleMapsOnAssignmentList:
    'disableToLeveGoogleMapsOnAssignmentList',
  defaultZoomByFs: 'defaultZoomByFs',
  enableMarkerClustererGoogleMaps: 'enableMarkerClustererGoogleMaps',
  pickupScannerPossibleFormats: 'pickupScannerPossibleFormats',
  enableUseScannerFormatsFromFS: 'enableUseScannerFormatsFromFS',
  enableNewPickupScannerFlowForDriverByRelation:
    'enableNewPickupScannerFlowForDriverByRelation',
  enableNewFlowBeyond: 'enableNewFlowBeyond',
  enableExpiredDisputeResolution: 'enableExpiredDisputeResolution',
  maxBarcodeLengthToTruncate: 'maxBarcodeLengthToTruncate',
  minBarcodeLengthToTruncate: 'minBarcodeLengthToTruncate',
  enableNewPickupProblemsPage: 'enableNewPickupProblemsPage',
  enableEventPackageWithoutBarcode: 'enableEventPackageWithoutBarcode',
  enablePlaceholder: 'enablePlaceholder',
  enableNewPickupScannerFlowPhaseThree: 'enableNewPickupScannerFlowPhaseThree',
  enableMarkerClustererGoogleMapsByLmc: 'enableMarkerClustererGoogleMapsByLmc',
  linkToNewsUrl: 'linkToNewsUrl',
  enableBannerCognito: 'enableBannerCognito',
  enableModality2Disputes: 'enableModality2Disputes',
  enablePermissionNotification: 'enablePermissionNotification',
  maxPermissionNotification: 'maxPermissionNotification',
  daysToPermissionNotification: 'daysToPermissionNotification',
  enableFixSignInWithAnotherAccount: 'enableFixSignInWithAnotherAccount',
  deadlineTextToAssociateCognito: 'deadlineTextToAssociateCognito',
  enableSendDataToInsider: 'enableSendDataToInsider',
  showOnlyLastDigitsSealBag: 'showOnlyLastDigitsSealBag'
};

export default featureSwitches;
