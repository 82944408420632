import React from 'react';

const messages = {
  title: 'Entrega não encontrada pelo destinatário',
  subtitle:
    'O pacote consta como entregue, mas o destinatário informou que não recebeu.',
  description: trackingKey => (
    <>
      O pacote <strong>{trackingKey}</strong> consta como entregue, mas o
      destinatário informou que não recebeu. Se você estiver com o pacote, leve
      ao local onde você retirou.
    </>
  ),
  warningTitle: 'Fique ligado',
  warningBody:
    'Sua entrega está em aberto. Você não precisa entrar em contato com o destinatário, aguarde até o alerta de entrega não encontrada sair da sua tela inicial.',
  buttonBack: 'Ok, entendi',
  status: {
    pending: 'Aguardando devolução',
    solved: 'Resolvida',
    waiting_analysis: 'Aguardando análise',
    confirmation_not_accepted: 'Confirmação não aceita',
    expired: 'Expirado',
    solved_after_expired: 'Resolvida depois de expirada'
  },
  empty: {
    title: 'Nenhuma confirmação por enquanto',
    description:
      'Pode ser que um pacote entregue precise ser confirmado com o destinatário. Quando isso acontecer, a gente te avisa por aqui.'
  },
  error: {
    title: 'Aconteceu um erro por aqui.',
    description: 'Pode ser que o servidor esteja com problemas no momento.',
    buttonText: 'Tentar novamente'
  }
};

export default messages;
