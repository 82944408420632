import PropTypes from 'prop-types';
import { companyRelation } from 'view/pages/emergency';
import { itineraryTypes } from 'view/pages/home/constants';

const driverInfoPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  availableForNotifications: PropTypes.bool,
  driverProfilePictureUrl: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  operationalStatus: PropTypes.string,
  onboardTime: PropTypes.string,
  companyType: PropTypes.string,
  companyRelation: PropTypes.oneOf(Object.values(companyRelation)),
  itineraryType: PropTypes.oneOf(Object.values(itineraryTypes)),
  pendingActivitiesCount: PropTypes.number,
  showcaseEnabled: PropTypes.bool,
  menu: PropTypes.arrayOf(PropTypes.string),
  driverAppMinVersion: PropTypes.number,
  onlySupport: PropTypes.bool
});

export default driverInfoPropType;
