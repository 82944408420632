import { validate as isCnpjValid } from 'cnpj';
import { isValid as isCpfValid } from 'cpf';

const cpfType = '2';

export const validateCnpjCpf = (cnpjCpf, documentType) => {
  if (!cnpjCpf || !documentType) return false;
  if (documentType === cpfType) return isCpfValid(cnpjCpf);
  return isCnpjValid(cnpjCpf);
};

export default validateCnpjCpf;
