import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from 'view/images/document_validation_error.svg';
import { useHistory } from 'react-router-dom';
import { messages } from './constants';

export default function DocumentErrorState() {
  const history = useHistory();

  return (
    <Box
      flexGrow={1}
      textAlign="center"
      flexDirection="column"
      display="flex"
      px={2}
      paddingBottom={5}
    >
      <Box>
        <ErrorIcon />
      </Box>
      <Box mt={3} mb={3}>
        <Typography variant="h6">{messages.documentError.title}</Typography>
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body1">
          {messages.documentError.description}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => history.goBack()}
        fullWidth
      >
        {messages.documentError.buttonText}
      </Button>
    </Box>
  );
}
