import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from 'view/images/error-generic.svg';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { messages } from './constants';

export default function ErrorState({ errorMessage }) {
  const history = useHistory();

  return (
    <Box
      flexGrow={1}
      textAlign="center"
      flexDirection="column"
      display="flex"
      p={5}
    >
      <Box mt={10} mb={7}>
        <ErrorIcon />
      </Box>
      <Box mb={7}>
        <Typography variant="h5">{messages.error.title}</Typography>
      </Box>
      <Box>
        <Typography variant="body2">{messages.error.description}</Typography>
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body2">{errorMessage}</Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => history.goBack()}
        fullWidth
      >
        {messages.error.buttonText}
      </Button>
    </Box>
  );
}

ErrorState.propTypes = {
  errorMessage: PropTypes.string.isRequired
};
