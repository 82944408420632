import showSnackbar from 'shared/snackbar';

export default function ErrorHandling(enqueueSnackbar) {
  return error => {
    const { message } = error?.errors ? error.errors[0] : {};
    showSnackbar({
      message,
      variant: 'error',
      enqueueSnackbar
    });
  };
}
