const onboardingDialogKey = 'onboarding-dialog';

export function clearStorage() {
  localStorage.clear();
}

export function shouldOpenOnboardingDialog() {
  return JSON.parse(localStorage.getItem(onboardingDialogKey)) === null;
}

export function setOpenOnboardingDialog(open) {
  localStorage.setItem(onboardingDialogKey, open);
}

const automaticRedirectRouteKey = 'already-redirected-flecha';

export function shouldRedirectToFlecha() {
  return JSON.parse(localStorage.getItem(automaticRedirectRouteKey)) === null;
}

export function setRedirectToFelcha(open) {
  localStorage.setItem(automaticRedirectRouteKey, open);
}

export function removeRedirectToFlecha(open) {
  localStorage.removeItem(automaticRedirectRouteKey, open);
}

const lastUpdateDriverInfo = 'last-update-driver-info';
export function setLastUpdateDriverInfo(date) {
  localStorage.setItem(lastUpdateDriverInfo, date);
}

export function getLastUpdateDriverInfo() {
  return localStorage.getItem(lastUpdateDriverInfo);
}

const lastPermissionNotification = 'new-last-permission-notification';
export function setLastPermissionNotification(date) {
  localStorage.setItem(lastPermissionNotification, date);
}

export function getLastPermissionNotification() {
  return localStorage.getItem(lastPermissionNotification);
}

const countPermissionNotification = 'new-count-permission-notification';
export function setCountPermissionNotification(count) {
  localStorage.setItem(countPermissionNotification, count);
}

export function getCountPermissionNotification() {
  return localStorage.getItem(countPermissionNotification);
}
