import { colors } from '@loggi/mar';

import { ReactComponent as ErrorOnRegistrationIcon } from 'view/images/error_on_registration.svg';
import { ReactComponent as WaitingToBeEnabledIcon } from 'view/images/waiting_to_be_enabled.svg';

export const driverStatus = {
  incompleteRegistration: 'cadastro_incompleto',
  inQueue: 'na_fila',
  licensed: 'habilitado',
  blocked: 'bloqueado',
  rescinded: 'rescindido',
  disabled: 'desabilitado',
  inconsistent: 'inconsistente'
};

export const registrationError = operationalStatus =>
  operationalStatus === driverStatus.inQueue
    ? {
        color: colors.yellow[50],
        bulletColor: colors.yellow[700],
        text: 'Agora é só aguardar!'
      }
    : {
        color: colors.red[50],
        bulletColor: colors.red[700],
        text: 'Atualize seu cadastro agora!'
      };

const messageErrorRegistrationDialogBlocked = {
  title: 'Chegou a hora de atualizar o seu cadastro!',
  subtitle:
    'Para voltar a aceitar rotas e usar o aplicativo normalmente, atualize os documentos a seguir.',
  button: 'Atualizar cadastro',
  Icon: ErrorOnRegistrationIcon,
  canClose: true,
  contactLoggi: false
};

const messageErrorRegistrationDialogInQueue = driverName => ({
  ...messageErrorRegistrationDialogBlocked,
  title: `${driverName}, tudo certo, agora é só aguardar! 🎉`,
  subtitle: 'Assim que você puder começar a entregar, vamos te avisar.',
  button: 'Entre em contato com a Loggi',
  Icon: WaitingToBeEnabledIcon,
  contactLoggi: true
});

const messageErrorRegistrationDialogDisabled = driverName => ({
  ...messageErrorRegistrationDialogInQueue(driverName),
  title: `${driverName}, sua conta está inativa`,
  subtitle: 'Entre em contato com a Loggi para mais informações.',
  Icon: ErrorOnRegistrationIcon,
  canClose: false
});

export const messageErrorRegistrationDialog = (operationalStatus, driverName) =>
  ({
    [driverStatus.incompleteRegistration]: messageErrorRegistrationDialogBlocked,
    [driverStatus.inQueue]: messageErrorRegistrationDialogInQueue(driverName),
    [driverStatus.blocked]: messageErrorRegistrationDialogBlocked,
    [driverStatus.rescinded]: messageErrorRegistrationDialogDisabled(
      driverName
    ),
    [driverStatus.disabled]: messageErrorRegistrationDialogDisabled(driverName),
    [driverStatus.inconsistent]: messageErrorRegistrationDialogDisabled(
      driverName
    )
  }[operationalStatus]);
