import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'app';
import { ReactComponent as SuccessIcon } from './images/success-state.svg';
import { messages } from './constants';

export const SUCCESS_BACK_BUTTON = 'success-back-button';

export default function SuccessState() {
  const history = useHistory();
  const { location } = window;

  return (
    <Box
      flexGrow={1}
      textAlign="center"
      flexDirection="column"
      display="flex"
      p={5}
    >
      <Box mt={10} mb={7}>
        <SuccessIcon />
      </Box>
      <Box mb={7}>
        <Typography variant="h4">{messages.success.title}</Typography>
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body1">{messages.success.description}</Typography>
      </Box>
      <Button
        data-testid={SUCCESS_BACK_BUTTON}
        variant="contained"
        color="primary"
        size="large"
        onClick={() =>
          history.push({
            pathname: PAGES.DELIVERY_CONFIRMATION,
            search: location.search
          })
        }
        fullWidth
      >
        {messages.success.buttonText}
      </Button>
    </Box>
  );
}
