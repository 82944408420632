import React, { useState } from 'react';
import {
  Box,
  Typography,
  Dialog,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as LoggiIcon } from 'view/images/loggi.svg';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { GOOGLE_PROVIDER } from '@loggi/authentication/src/screens/signin/constants';
import { ReactComponent as AssociateGoogleAccountIcon } from 'view/images/associate-google-account.svg';
import { pages } from '@loggi/driver-authentication';
import { logoutAndPrepareNewSession } from 'operations/auth/credentials';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import featureSwitches from 'operations/feature-switches';

export const config = {
  title: 'Acesse o aplicativo com sua conta Google desde já',
  button: 'Associar conta Google'
};

export default function CognitoDialog({ onClose }) {
  const { federatedSignIn } = useAmplifyAuth();
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const handleClose = () => {
    setIsDialogOpen(false);
    onClose();
  };

  const deadlineTextToAssociateCognito = useFeatureSwitch(
    featureSwitches.deadlineTextToAssociateCognito
  );

  const { title, button } = config;
  const subtitle = `A partir de ${deadlineTextToAssociateCognito}, para entrar no aplicativo, será necessário uma conta Google. Para isso, associe um e-mail ao seu usuário Loggi.`;

  const handleAssociateGoogleAccount = () => {
    logoutAndPrepareNewSession();
    localStorage.setItem('redirectToAssociateCognitoPage', true);
    federatedSignIn(GOOGLE_PROVIDER, pages.login);
  };

  return (
    <Dialog fullScreen open={isDialogOpen}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Grid container justify="space-between" alignItems="center">
          <LoggiIcon height="34px" data-testid="loggiIcon" />
          <IconButton onClick={handleClose} data-testid="closeButonIcon">
            <CloseIcon color="primary" />
          </IconButton>
        </Grid>
        <Box pt={6} px={2.5} flexGrow={1}>
          <AssociateGoogleAccountIcon
            style={{ marginBottom: '1rem' }}
            data-testid="cognitoAssociateIcon"
          />
          <Typography variant="h5" style={{ marginBottom: '1rem' }}>
            {title}
          </Typography>
          <Typography>{subtitle}</Typography>
        </Box>
        <Box pt={2} p={3} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={handleAssociateGoogleAccount}
          >
            {button}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
CognitoDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};
