import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { messages } from './constants';
import { ReactComponent as SuccessIcon } from './images/success-state.svg';
import { ReactComponent as ExpiredIcon } from './images/expired-state.svg';

export function ExpiredConfirmationDetails({ disputeDetails }) {
  return (
    <>
      <Box color={colors.root[900]}>
        <Typography variant="h6">{messages.expired.title}</Typography>
      </Box>
      <Box>
        <Box key={disputeDetails.id} mt={2.5} color={colors.smoke[800]}>
          <Box py={1.5}>
            <Typography variant="h6" fontWeight="fontWeightBold">
              {disputeDetails.packageCompanyName}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {disputeDetails.address}
            </Typography>
          </Box>
          <Box my={3}>
            <Typography variant="body2">
              {messages.detail.whoReceive}
            </Typography>
            <Box my={0.5}>
              <Typography variant="subtitle2">
                {disputeDetails.taskReceiver}
              </Typography>
            </Box>
          </Box>
          {disputeDetails.taskNotes && (
            <Box mt={3}>
              <Typography variant="body2">
                {messages.detail.deliveryInformation}
              </Typography>
              <Box my={0.5}>
                <Typography variant="subtitle2">
                  {disputeDetails.taskNotes}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

ExpiredConfirmationDetails.propTypes = {
  disputeDetails: PropTypes.shape({
    id: PropTypes.number,
    packageCompanyName: PropTypes.string,
    address: PropTypes.string,
    taskReceiver: PropTypes.string,
    taskNotes: PropTypes.string
  }).isRequired
};

export function ExpiredDeliveryLiberate() {
  return (
    <Box bgcolor={colors.root[0]}>
      <Box display="flex" justifyContent="center" alignItems="center" mt={2.5}>
        <SuccessIcon />
      </Box>
      <Box display="flex" justifyContent="center" textAlign="center" mt={2.5}>
        <Typography variant="h4">{messages.expired.liberateTitle}</Typography>
      </Box>
      <Box display="flex" justifyContent="center" textAlign="center" mt={2.5}>
        <Typography variant="body1">{messages.expired.liberateText}</Typography>
      </Box>
    </Box>
  );
}

export function ExpiredWithoutResolution() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box px={2.5} bgcolor={colors.root[0]}>
      <Box
        px={2.5}
        pt={3.5}
        pb={1}
        color={colors.blue[500]}
        aria-label={messages.buttonBack}
      >
        <IconButton onClick={handleGoBack} data-testid="close-icon">
          <CloseIcon color="primary" />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" mt={6}>
        <ExpiredIcon />
      </Box>
      <Box
        mb={1}
        mt={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="h6">
          {messages.expired.titleWithoutResolution}
        </Typography>
        <Box mt={3}>
          <Typography variant="body1">
            {messages.expired.descriptionWithoutResolution}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
