import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Button } from '@material-ui/core';
import ShowcasePage, {
  registerOfferListAccess,
  OFFER_LIST_ACCESS_SOURCE_HOME_BUTTON
} from '@loggi/driverapp-routes-list';

import { driverInfoPropType } from 'shared/prop-type/index';
import { ReactComponent as DriverIcon } from 'view/images/driver.svg';
import { ReactComponent as NoneOfferIcon } from 'view/images/none_offer.svg';
import getFirebaseAnalyticsData from 'view/utils';
import sendEventToAnalytics from 'operations/firebase';
import {
  analyticsCategories,
  analyticsEventNames
} from 'operations/firebase/constants';
import messages from './constants';
import { menuItens } from '../menu/constants';

export default function OfferShowcaseComponent({ driverInfo }) {
  const history = useHistory();
  const { location } = window;
  const hasActivities = driverInfo?.pendingActivitiesCount > 0;
  const renderShowcase = driverInfo?.showcaseEnabled;

  const { avaliableRoutes } = menuItens;
  const { action } = avaliableRoutes({ history, location });

  if (renderShowcase) {
    return <ShowcasePage driverInfo={driverInfo} onOfferSearchClick={action} />;
  }

  const pageTitle = 'Home - OfferSowcase';

  const firebaseAnalyticsData = getFirebaseAnalyticsData(
    driverInfo,
    analyticsCategories.newHome,
    pageTitle
  );

  const data = {
    withActivities: {
      icon: <DriverIcon height="60px" data-testid="driver-icon" />,
      title: messages.finishItineraryTitle,
      subtitle: messages.finishItinerarySubtitle
    },
    withoutActivities: {
      icon: <NoneOfferIcon height="60px" data-testid="none-offer-icon" />,
      title: messages.offerNone,
      subtitle: messages.offerNoneSubtitle
    }
  };

  const { icon, title, subtitle } = hasActivities
    ? data.withActivities
    : data.withoutActivities;

  return (
    <>
      <Box
        pb={2.5}
        px={2.5}
        flexGrow={1}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
      >
        {icon}
        <Box py={1}>
          <Typography variant="subtitle2">
            <Box component="span" fontWeight="fontWeightBold">
              {title}
            </Box>
          </Typography>
        </Box>
        <Typography variant="subtitle2">{subtitle}</Typography>
      </Box>
      <Box pb={2.5} px={2.5} width="100%">
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => {
            sendEventToAnalytics(
              analyticsEventNames.clickButtonFindOffers,
              firebaseAnalyticsData
            );
            registerOfferListAccess(OFFER_LIST_ACCESS_SOURCE_HOME_BUTTON);
            action();
          }}
        >
          {messages.offerSearch}
        </Button>
      </Box>
    </>
  );
}

OfferShowcaseComponent.propTypes = {
  driverInfo: driverInfoPropType
};

OfferShowcaseComponent.defaultProps = {
  driverInfo: {}
};
