export const messages = {
  hello: 'Olá,',
  deliveryConfirmationsWarning: 'Confirmar entregas para ver ofertas',
  deliveryConfirmationsWarningModality2:
    'Entrega não encontrada pelo destinatário',
  offline: 'Você não está conectado',
  lastUpdate: 'Última atualização:'
};

export const linkToSuport =
  'https://ajuda.loggi.com/hc/pt-br/requests/new?ticket_form_id=160657';

export const linkToRegisterHelper =
  'https://ajuda.loggi.com/hc/pt-br/sections/4409970016781-Cadastro-e-conta';

export const itineraryTypes = {
  itinerary_type_invalid: 'ITINERARY_TYPE_INVALID',
  itinerary_type_pro: 'ITINERARY_TYPE_PRO',
  itinerary_type_corp: 'ITINERARY_TYPE_CORP',
  itinerary_type_proish: 'ITINERARY_TYPE_PROISH'
};
