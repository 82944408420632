import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'app';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import colors from '@loggi/mar/src/themes/drivers/colors';
import { Box, Typography, Chip, ThemeProvider } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { getDisputes } from 'operations/disputes';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { ArrowForward } from '@material-ui/icons';
import EmptyState from '../delivery-confirmation/empty-state';
import Loading from '../delivery-confirmation/loading';
import messages from './constants';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function DeliveryNotFoundPage() {
  const history = useHistory();
  const { location } = window;
  const { enqueueSnackbar } = useSnackbar();
  const [disputes, setDisputes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const driver = history.location.state;

  const disputeStatus = {
    confirmationNotAccepted: 'confirmation_not_accepted',
    expired: 'expired',
    pending: 'pending',
    solved: 'solved',
    solved_after_expired: 'solved_after_expired'
  };

  const disputeType = 'modalidade_2';

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    setIsLoading(true);
    getDisputes({ modality: disputeType, status: disputeStatus.pending })
      .then(response => {
        setDisputes(response?.disputes || []);
      })
      .catch(error => {
        const { errors } = error;
        const { message } = errors?.[0] || {};

        showSnackbar({
          message,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar]);

  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <Box display="flex" minHeight="100%" flexDirection="column">
        <Box flexGrow={1}>
          <Box px={2.5} pt={3.6} pb={1} aria-label={messages.buttonBack}>
            <ArrowBack onClick={handleGoBack} data-testid="arrow-back" />
          </Box>

          <Box px={2.5}>
            <Typography variant="h5">
              <strong>{messages.title}</strong>
            </Typography>
          </Box>
          <Box px={2.5} pt={2}>
            <Typography variant="body1">{messages.subtitle}</Typography>
          </Box>
          {isLoading && <Loading />}
          {!isLoading && !!disputes.length && (
            <Box
              borderBottom={1}
              pb={2.5}
              style={{ borderColor: colors.neutrals.shapes.light }}
            >
              {disputes.map(dispute => (
                <Box
                  key={dispute.disputeId}
                  px={2.5}
                  mt={2.5}
                  pt={2}
                  borderColor={colors.neutrals.shapes.light}
                  borderTop={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  onClick={() => {
                    history.push(
                      {
                        pathname: PAGES.DELIVERY_NOT_FOUND_DETAILS.replace(
                          ':id',
                          dispute.disputeId
                        ),
                        search: location.search
                      },
                      { driver, dispute }
                    );
                  }}
                >
                  <Box
                    pb={1}
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    {dispute.disputeStatus === disputeStatus.pending && (
                      <Chip
                        size="small"
                        style={{
                          backgroundColor: colors.brand.light,
                          color: colors.primary.pure
                        }}
                        label={messages.status.pending}
                      />
                    )}
                    <ArrowForward />
                  </Box>
                  <Typography
                    variant="body2"
                    style={{ color: colors.neutrals.typeface.secondary }}
                  >
                    Código do pacote
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 'bold', marginTop: 4 }}
                  >
                    {dispute.packageTrackingKey}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          {!isLoading && !disputes.length && <EmptyState />}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
