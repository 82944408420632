import React from 'react';
import { Box, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { colors } from '@loggi/mar';
import { PAGES } from 'app';
import { useHistory } from 'react-router';
import { driverInfoPropType } from 'shared/prop-type/index';
import { registrationError, driverStatus } from './constants';

export default function ErrorRegistrationAlert({ driverInfo }) {
  const history = useHistory();

  if (
    driverInfo?.operationalStatus === driverStatus.licensed ||
    driverInfo?.onlySupport
  ) {
    return <></>;
  }

  const onFixRegistrationProblem = event => {
    event.preventDefault();
    history.push({
      pathname: PAGES.DRIVER_SIGN_UP,
      search: window.location.search
    });
  };

  const { bulletColor, color, text } = registrationError(
    driverInfo.operationalStatus
  );

  return (
    <Box
      component="a"
      my={1}
      py={1}
      pl={1}
      pr={2}
      borderRadius="100px"
      bgcolor={color}
      color={colors.smoke[900]}
      display="flex"
      alignItems="center"
      onClick={onFixRegistrationProblem}
      style={{ cursor: 'pointer' }}
    >
      <Box component="span" color={bulletColor} pl={1} mb={0.5}>
        <Typography variant="h5">●</Typography>
      </Box>
      <Box flexGrow={1} fontWeight={600} pl={1}>
        {text}
      </Box>
      <ArrowForwardIosIcon fontSize="small" />
    </Box>
  );
}

ErrorRegistrationAlert.propTypes = {
  driverInfo: driverInfoPropType.isRequired
};
