import React from 'react';
import { useHistory } from 'react-router-dom';
import { isEmbeddedInDriverApp } from '@loggi/drivers-app-version';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';

import { PAGES } from 'app';
import { storeImpersonateWithApiKey } from 'operations/auth/credentials';
import { getParameter } from 'operations/auth/url-parser';
import featureSwitches from 'operations/feature-switches';
import HomeSkeleton from 'view/molecules/home-skeleton';

export default function ImpersonateApiKey() {
  const isCognitoAuth = useFeatureSwitch(featureSwitches.enableCognitoAuth);
  const history = useHistory();
  const email = getParameter('email');
  const apikey = getParameter('key');

  if (!isCognitoAuth && !isEmbeddedInDriverApp() && email && apikey) {
    storeImpersonateWithApiKey({ email, apikey });
    window.driverAppBridge = {
      getAppVersion: () => 999,
      isFromNewHome: () => true,
      getLWApiKey: () => `ApiKey ${email}:${apikey}`,
      trackEvent: () => {},
      logout: () => {}
    };
    history.push(PAGES.HOME);
  } else {
    history.push(PAGES.ROOT);
  }

  return <HomeSkeleton />;
}
