import { getParameter } from '../auth/url-parser';

const LAST_KNOWN_LOCATION = 'last_known_location';
const LOCATION_ERROR = 'location_error';

export const defaultLocationResponse = {
  accuracy: null,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
  latitude: null,
  longitude: null,
  speed: null
};

/**
 * This will set an error object containing location error code and
 * message related to the error when get the geolocation position from
 * watchPosition function.
 *
 * @param {GeolocationPositionError}
 * @see {@link https://w3c.github.io/geolocation-api/#position_error_interface}
 */
function setLocationError(error) {
  const locationError = {
    code: error.code,
    message: error.message
  };
  localStorage.setItem(LOCATION_ERROR, JSON.stringify(locationError));
}

/**
 * Clean position error data from
 * local storage.
 */
function clearLocationError() {
  localStorage.setItem(LOCATION_ERROR, '');
}

/**
 * JSON.stringify does not preserve any of the not-owned properties of the object,
 * it means properties inherited from Prototype will not be parsed, so we need
 * to extract the properties from coords to a new object
 *
 * @param {GeolocationCoordinates} coords
 * @see {@link https://w3c.github.io/geolocation-api/#coordinates_interface}
 */
function setLastKnownLocation(coords) {
  const defaultLastLocation = {
    ...defaultLocationResponse,
    latitude: coords.latitude,
    longitude: coords.longitude,
    updated: Date.now()
  };

  localStorage.setItem(
    LAST_KNOWN_LOCATION,
    JSON.stringify(defaultLastLocation)
  );
}

function setCurrentPositionAsLastKnownLocation() {
  window.navigator.geolocation.getCurrentPosition(
    pos => {
      setLastKnownLocation(pos.coords);
      clearLocationError();
    },
    err => {
      setLocationError(err);
    },
    {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }
  );
}

function getLastKnownLocation() {
  const location = JSON.parse(localStorage.getItem(LAST_KNOWN_LOCATION));

  /**
   * location is only valid if it is recently max last known location time is 5 minutes
   * in milliseconds it is 300000
   */
  const maxAgeLocationUpdate = 300000;
  if (
    location &&
    location.updated &&
    Date.now() - location.updated <= maxAgeLocationUpdate
  ) {
    return location;
  }

  return null;
}

/**
 * This will return the location error object saved in local storage
 *
 */
function getLocationError() {
  return JSON.parse(localStorage.getItem(LOCATION_ERROR));
}

/**
 * Call watchPosition to start to get user geolocation
 * coordinates. It defines the callback functions to deal
 * with success and error while collecting position
 */
function setWatchPosition() {
  const positionCollectedWithSuccess = pos => {
    setLastKnownLocation(pos.coords);
    clearLocationError();
  };

  const positionError = err => {
    setLocationError(err);
  };

  const options = {
    enableHighAccuracy: true,
    timeout: 1000,
    maximumAge: 0
  };

  const browserGeolocation = window.navigator && window.navigator.geolocation;

  browserGeolocation.watchPosition(
    positionCollectedWithSuccess,
    positionError,
    options
  );
}

/**
 * Interface to retrieve user lat/lng information
 *
 * @param {function(float: lat, float: lng, [string: error message])} callback
 */
export default function geolocation() {
  let lastKnownLocation = getLastKnownLocation();

  if (
    lastKnownLocation !== null &&
    lastKnownLocation.latitude !== null &&
    lastKnownLocation.longitude !== null
  ) {
    return Promise.resolve(lastKnownLocation);
  }

  const latitude = parseFloat(getParameter('latitude'));
  const longitude = parseFloat(getParameter('longitude'));

  if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
    return Promise.resolve({
      ...defaultLocationResponse,
      latitude,
      longitude
    });
  }

  setCurrentPositionAsLastKnownLocation();
  lastKnownLocation = getLastKnownLocation();

  if (lastKnownLocation !== null) {
    return Promise.resolve(lastKnownLocation);
  }

  return Promise.resolve(defaultLocationResponse);
}

export {
  setLocationError,
  getLastKnownLocation,
  getLocationError,
  setLastKnownLocation,
  setWatchPosition
};
