import React from 'react';
import { colors } from '@loggi/mar';
import { Box, Typography } from '@material-ui/core';

import { messages } from './constants';

export default function Receipt() {
  return (
    <Box pb={2.5}>
      <Box color={colors.root[900]}>
        <Typography variant="h5">{messages.receipt.title}</Typography>
      </Box>
      <Box mt={2.5} color={colors.smoke[800]}>
        <Box>
          <Typography variant="subtitle1">
            {messages.receipt.description.firstLine}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle1">
            {messages.receipt.description.secondLine}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
