import { add, parseISO } from 'date-fns';
import {
  getLastPermissionNotification,
  setLastPermissionNotification,
  getCountPermissionNotification,
  setCountPermissionNotification
} from 'operations/config/storage';

const MAX_NOTIFICATIONS_DEFAULT_VALUE = 3;
const DAYS_TO_NOTIFICATIONS_DEFAULT_VALUE = 1;

export function shouldNotififyDriverAboutPermission(
    enablePermissionNotification, 
    maxPermissionNotificationFS, 
    daysToPermissionNotificationFS
  ) {
    
    if (!enablePermissionNotification) {
      return false;
    }
  
    const maxPermissionNotification = maxPermissionNotificationFS || MAX_NOTIFICATIONS_DEFAULT_VALUE;
    const countPermissionNotification = getCountPermissionNotification();
    if (
      countPermissionNotification &&
      parseInt(countPermissionNotification, 10) >= maxPermissionNotification
    ) {
      return false;
    }
  
    const daysToPermissionNotification = daysToPermissionNotificationFS || DAYS_TO_NOTIFICATIONS_DEFAULT_VALUE;
    const lastPermissionNotification = getLastPermissionNotification();
    if (
      lastPermissionNotification &&
      new Date() <
        add(parseISO(lastPermissionNotification), {
          days: daysToPermissionNotification
        })
    ) {
      return false;
    }
  
    return window.driverAppBridge?.isNotificationPermissionDisabled?.();
  }
  
  export function setParamsLastPermissionNotification() {
    const countPermissionNotification = getCountPermissionNotification();
    const newCountPermissionNotification = countPermissionNotification
      ? parseInt(countPermissionNotification, 10) + 1
      : 1;
    const lastPermissionNotification = new Date();
    setCountPermissionNotification(newCountPermissionNotification);
    setLastPermissionNotification(`${lastPermissionNotification.toISOString()}`);
  }