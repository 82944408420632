import { PAGES } from 'app';
import sendEventToAnalytics from 'operations/firebase';
import { analyticsCategories } from 'operations/firebase/constants';
import getFirebaseAnalyticsData from 'view/utils';
import { itineraryTypes } from './constants';

export const openFlecha = history => () => {
  return history.push({
    pathname: PAGES.FLECHA,
    search: window.location.search
  });
};

export const openCorpProish = () => window.driverAppBridge.openCorpProish();

export const openRoute = (history, itineraryType) => {
  const shouldOpenCorpProish = [
    itineraryTypes.itinerary_type_corp,
    itineraryTypes.itinerary_type_proish
  ].includes(itineraryType);

  return shouldOpenCorpProish ? openCorpProish : openFlecha(history);
};

export const openDeliveryConfirmations = (history, driver) => {
  const pageTitle = 'Home';
  const eventLabel = 'Confirmar entregas';
  const clickActionBarEventName = 'click_action_bar';
  const firebaseAnalyticsData = getFirebaseAnalyticsData(
    driver,
    analyticsCategories.newHome,
    pageTitle
  );

  firebaseAnalyticsData.event_label = eventLabel;

  sendEventToAnalytics(clickActionBarEventName, firebaseAnalyticsData);

  history.push(
    {
      pathname: PAGES.DELIVERY_CONFIRMATION,
      search: history.location.search
    },
    { driver }
  );
};
