import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import PropTypes from 'prop-types';

import { PAGES } from 'app';
import isAuthenticatedWithCognitoOrApiKey, {
  getImpersonateWithApiKey,
  isSupport
} from 'operations/auth/credentials';
import HomeSkeleton from 'view/molecules/home-skeleton';

/**
 * A private route is a {Route} that is used when it must be protected from anonymous access.
 * Only logged users are able to reach it and any unlogged user will be redirected to the login page.
 * Its parameters are the same as Route (mainly path) and are passed to it.
 *
 * @param {String} path the path representing the route
 * @param {Function} component the page to be rendered at path
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {
    state: { authenticatedUser: user, loading }
  } = useAmplifyAuth();
  const { search } = window?.location || {};

  // A route that can only be access by CX Support
  const { isSupportRoute, location } = rest;

  if (loading) {
    return <HomeSkeleton />;
  }

  let redirectProps = {
    pathname: PAGES.LOGIN,
    state: { from: location }
  };
  if (getImpersonateWithApiKey()) {
    const { email, apikey } = getImpersonateWithApiKey();

    redirectProps = {
      ...redirectProps,
      pathname: PAGES.IMPERSONATE_APIKEY,
      search: `${search}&email=${email}&key=${apikey}`
    };
  }

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
      render={props =>
        (isAuthenticatedWithCognitoOrApiKey(user) && !isSupportRoute) ||
        (isSupport(user) && isSupportRoute) ? (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <Component {...props} />
        ) : (
          <Redirect to={redirectProps} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(Location)
  ]),
  path: PropTypes.string
};

PrivateRoute.defaultProps = {
  location: null,
  path: ''
};

export default PrivateRoute;
