import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import Skeleton from '@material-ui/lab/Skeleton';

export default function LoadingDisputes() {
  const loaders = ['1', '2', '3'];

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        pt={4.5}
        px={2.5}
        pb={2.5}
        width="100%"
        display="flex"
        flexDirection="column"
        borderTop={1}
        borderColor={colors.smoke[200]}
      >
        <Box mt={4}>
          {loaders.map(i => (
            <Box key={i}>
              <Typography variant="h4">
                <Skeleton width="64px" />
              </Typography>

              <Typography variant="h4">
                <Skeleton width="80%" />
                <Skeleton width="80%" />
              </Typography>

              <Box>
                <Skeleton />
                <Skeleton />
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
