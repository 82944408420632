import { PopupButton } from '@typeform/embed-react';
import decamelizeKeys from 'decamelize-keys';
import { getEmailFromCredentials } from 'operations/auth/credentials';
import { getParameter } from 'operations/auth/url-parser';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function ShowcaseSurvey() {
  const formId = `${process.env.REACT_APP_SHOWCASE_SURVEY_FORM_ID}`;
  const itineraryId = getParameter('itineraryId');
  const driverEmail = getEmailFromCredentials();
  const history = useHistory();

  const alreadySawSurvey =
    localStorage.getItem('showcase_survey_submitted') ||
    localStorage.getItem('showcase_survey_closed');

  const hiddenFields = decamelizeKeys({
    driverEmail,
    itineraryId
  });

  const registerSurveySubmit = () => {
    localStorage.setItem('showcase_survey_submitted', 'true');
  };

  const registerSurveyClose = () => {
    localStorage.setItem('showcase_survey_closed', 'true');
    history.goBack();
  };

  if (alreadySawSurvey) {
    history.goBack();
  }

  return (
    <PopupButton
      id={formId}
      hidden={hiddenFields}
      open="load"
      autoClose={10}
      hideFooter
      hideHeaders
      enableSandbox={false}
      style={{ display: 'none' }}
      data-testid="showcase-survey-form"
      onClose={() => registerSurveyClose()}
      onSubmit={() => registerSurveySubmit()}
    />
  );
}

ShowcaseSurvey.url = '/pesquisa-showcase';
