import React from 'react';

import SnackbarMessage from './message';

const showSnackbar = ({ message, withButton, variant, enqueueSnackbar }) => {
  enqueueSnackbar(message, {
    content: (key, msg) => (
      <SnackbarMessage
        open
        id={key}
        message={msg}
        variant={variant}
        withButton={withButton}
      />
    )
  });
};

export default showSnackbar;
