import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { colors } from '@loggi/mar';
import { confirmDispute } from 'operations/disputes';
import getPosition from 'operations/geolocation';
import SignatureComponent from './signature-component';

import SuccessState from '../success-state';
import ErrorState from '../error-state';
import DocumentErrorState from '../document-error-state';
import SignatureControls from '../signature-controls';
import SignatureOverlay from '../signature-overlay';

export const SIGNATURE_BACK_BUTTON = 'signature-back-button';

export default function Signature() {
  const history = useHistory();
  const formData = history.location.state;

  const states = {
    loading: 'loading',
    initState: 'initState',
    showingSuccess: 'showingSuccess',
    showingError: 'showingError',
    showingSignature: 'showingSignature',
    showingDocumentError: 'showingDocumentError'
  };

  const [signature, setSignature] = useState(undefined);
  const [clear, setClear] = useState(false);
  const [state, setState] = useState(states.initState);
  const [errorMessage, setErrorMessage] = useState('');

  async function onGetPosition() {
    const { latitude, longitude } = await getPosition();
    formData.latitude = latitude;
    formData.longitude = longitude;
  }

  const onContinue = () => {
    setState(states.loading);
    onGetPosition().then(() => {
      confirmDispute(formData)
        .then(() => {
          setState(states.showingSuccess);
        })
        .catch(error => {
          const { errors } = error;
          const { message } = errors && errors[0] ? errors[0] : '';
          setErrorMessage(message);

          if (error.code === 422) {
            setState(states.showingDocumentError);
          } else {
            setState(states.showingError);
          }
        });
    });
  };

  const onRedo = () => setClear(true);

  const onChange = image => {
    setSignature(image);
    formData.signatureContent = image;

    if (clear) {
      setClear(false);
    }
  };

  const onOverlayClick = () => setState(states.showingSignature);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box pt={0.5} display="flex" flexDirection="column">
        <Box py={2} px={0.5}>
          <IconButton
            onClick={() => history.goBack()}
            data-testid={SIGNATURE_BACK_BUTTON}
          >
            <ArrowBack style={{ color: colors.blue[500] }} />
          </IconButton>
        </Box>
      </Box>

      {state === states.initState && (
        <SignatureOverlay
          onOverlayClick={onOverlayClick}
          protocolRecipientName={formData.protocolRecipientName}
        />
      )}

      {(state === states.showingSignature || state === states.loading) && (
        <SignatureComponent
          style={{
            backgroundColor: colors.smoke[50],
            borderRadius: '16px',
            flexGrow: 1,
            position: 'relative'
          }}
          clear={clear}
          onChange={onChange}
          mx={2.5}
          data-testid="signature-container"
        />
      )}

      {state !== states.showingSuccess &&
        state !== states.showingError &&
        state !== states.showingDocumentError && (
          <SignatureControls
            isLoading={state === states.loading}
            onContinue={onContinue}
            onRedo={onRedo}
            disabled={signature === undefined}
          />
        )}

      {state === states.showingDocumentError && <DocumentErrorState />}

      {state === states.showingError && (
        <ErrorState errorMessage={errorMessage} />
      )}
      {state === states.showingSuccess && <SuccessState />}
    </Box>
  );
}
