import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  shouldRedirectToFlecha,
  setRedirectToFelcha
} from 'operations/config/storage';
import { AutomaticRedirectContext } from 'contexts/automatic-redirect-context';
import { driverInfoPropType } from 'shared/prop-type/index';
import { openRoute } from 'view/pages/home/actions';
import { driverStatus } from 'view/pages/home/error/registration/constants';

/**
 * Hook approach to open the current route if driver has activities
 * It takes the user to the route when opening the app because we want
 * the user to be redirected to the work itself from beginning, given it has
 * activities to work with.
 */
function useAutomaticRedirectToRoute(driverInfo) {
  const { shouldOpenRoute, openRouteHandler } = useContext(
    AutomaticRedirectContext
  );
  const history = useHistory();

  const hasActivities =
    driverInfo?.pendingActivitiesCount > 0 ||
    driverInfo?.acceptedPickupsCount > 0;

  if (
    hasActivities &&
    shouldOpenRoute &&
    driverInfo.operationalStatus === driverStatus.licensed &&
    shouldRedirectToFlecha()
  ) {
    setRedirectToFelcha(false);
    openRouteHandler(false);

    openRoute(history, driverInfo.itineraryType)();
  }
}

useAutomaticRedirectToRoute.propTypes = {
  driverInfo: driverInfoPropType
};

export default useAutomaticRedirectToRoute;
