import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { colors } from '@loggi/mar';

import { driverInfoPropType } from 'shared/prop-type/index';
import { ReactComponent as DoNotDisturbIcon } from 'view/images/do_not_disturb.svg';
import { ReactComponent as EnableNotificationsIcon } from 'view/images/enable_notifications.svg';
import { ReactComponent as LoggiIcon } from 'view/images/loggi.svg';
import sendEventToAnalytics from 'operations/firebase';
import getFirebaseAnalyticsData from 'view/utils';
import {
  analyticsCategories,
  analyticsEventNames
} from 'operations/firebase/constants';
import { PAGES } from 'app';
import { messages } from './constants';
import { menuItens, pageTitle } from './menu/constants';
import messagesOffer from './offer-showcase/constants';

export function HeaderComponent({
  flipAvailabilityForNotifications,
  isAvailableForNotifications,
  onlySupport
}) {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" justifyContent="center">
          <LoggiIcon height="34px" />
        </Box>

        {!onlySupport && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={flipAvailabilityForNotifications}
            style={{
              bgcolor: isAvailableForNotifications
                ? colors.root[0]
                : colors.blue[50],
              color: isAvailableForNotifications
                ? colors.smoke[700]
                : colors.smoke[900],
              border: isAvailableForNotifications
                ? `1px solid ${colors.smoke[100]}`
                : ''
            }}
          >
            {isAvailableForNotifications ? (
              <DoNotDisturbIcon />
            ) : (
              <EnableNotificationsIcon />
            )}
            <Box pl={1.5}>
              {isAvailableForNotifications
                ? messagesOffer.offerAvaliable
                : messagesOffer.offerBusy}
            </Box>
          </Button>
        )}
      </Box>
    </>
  );
}

HeaderComponent.propTypes = {
  flipAvailabilityForNotifications: PropTypes.func.isRequired,
  isAvailableForNotifications: PropTypes.bool.isRequired,
  onlySupport: PropTypes.bool
};
HeaderComponent.defaultProps = {
  onlySupport: false
};

export function PendingDisputesComponent({ driverInfo }) {
  const history = useHistory();
  const hasPendingDisputes = driverInfo?.pendingDisputesCount > 0;

  if (!hasPendingDisputes) {
    return <></>;
  }

  const firebaseAnalyticsData = getFirebaseAnalyticsData(
    driverInfo,
    analyticsCategories.newHome,
    pageTitle
  );

  sendEventToAnalytics(
    analyticsEventNames.viewBannerDispute,
    firebaseAnalyticsData
  );

  return (
    <Box
      component="a"
      my={1}
      py={1}
      pl={1}
      pr={2}
      borderRadius="100px"
      bgcolor={colors.red[50]}
      color={colors.smoke[900]}
      display="flex"
      alignItems="center"
      onClick={() =>
        menuItens
          .deliveryConfirmations({ history, driver: driverInfo })
          .action()
      }
      style={{ cursor: 'pointer' }}
    >
      <Box component="span" color={colors.red[700]} pl={1} mb={0.5}>
        <Typography variant="h5">●</Typography>
      </Box>
      <Box flexGrow={1} fontWeight={600} pl={1}>
        {messages.deliveryConfirmationsWarning}
      </Box>
      <ArrowForwardIosIcon fontSize="small" />
    </Box>
  );
}
PendingDisputesComponent.propTypes = {
  driverInfo: driverInfoPropType.isRequired
};

export function PendingDisputesModality2Component({ driverInfo }) {
  const history = useHistory();
  const hasPendingDisputesModality2 =
    driverInfo?.pendingDisputesModality2Count > 0;

  if (!hasPendingDisputesModality2) {
    return null;
  }

  return (
    <Box
      component="a"
      my={1}
      py={1}
      pl={1}
      pr={2}
      borderRadius="100px"
      bgcolor="#FAEACC"
      color={colors.smoke[900]}
      display="flex"
      alignItems="center"
      onClick={() =>
        history.push(
          {
            pathname: PAGES.DELIVERY_NOT_FOUND,
            search: history.location.search
          },
          { driverInfo }
        )
      }
      style={{ cursor: 'pointer' }}
    >
      <Box component="span" color={colors.yellow[700]} pl={1} mb={0.5}>
        <Typography variant="h5">●</Typography>
      </Box>
      <Box flexGrow={1} fontWeight={600} pl={1}>
        {messages.deliveryConfirmationsWarningModality2}
      </Box>
      <ArrowForwardIosIcon fontSize="small" />
    </Box>
  );
}
PendingDisputesModality2Component.propTypes = {
  driverInfo: driverInfoPropType.isRequired
};
