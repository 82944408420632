import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { colors } from '@loggi/mar';
import { Box, Typography, Button, ListItem } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getDisputeDetail, updateExpiredDispute } from 'operations/disputes';
import featureSwitches from 'operations/feature-switches';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import {
  ExpiredConfirmationDetails,
  ExpiredDeliveryLiberate,
  ExpiredWithoutResolution
} from './expired-states';
import Loading from './loading';
import EmptyState from './empty-state';
import ErrorState from './error-state';
import { messages, confirmationExpiredOptions } from './constants';

export default function DeliveryConfirmationExpiredPage() {
  const states = {
    loading: 'loading',
    showingDispute: 'showingDispute',
    showingOptions: 'showingOptions',
    showingLiberate: 'showingLiberate',
    nothingFound: 'nothingFound',
    showingError: 'showingError'
  };

  const history = useHistory();
  const { id } = useParams();
  const [disputeDetail, setDisputeDetail] = useState({});
  const [state, setState] = useState(states.loading);
  const [errorMessage, setErrorMessage] = useState('');
  const enableExpiredDisputeResolution = useFeatureSwitch(
    featureSwitches.enableExpiredDisputeResolution
  );

  useEffect(() => {
    setState(states.loading);
    getDisputeDetail(id)
      .then(response => {
        if (Object.keys(response).length > 0) {
          setState(states.showingDispute);
          setDisputeDetail(response);
        } else {
          setState(states.nothingFound);
        }
      })
      .catch(error => {
        const { errors } = error;
        const { message } = errors && errors[0] ? errors[0] : '';

        setErrorMessage(message);
        setState(states.showingError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOption = (disputeId, expiredReason) => {
    const payload = { expired_reason: expiredReason };
    updateExpiredDispute(disputeId, payload)
      .then(() => {
        setState(states.showingLiberate);
      })
      .catch(() => {
        setState(states.showingError);
      });
  };

  if (!enableExpiredDisputeResolution) {
    return <ExpiredWithoutResolution />;
  }

  return (
    <Box
      bgcolor={colors.root[0]}
      display="flex"
      minHeight="100%"
      flexDirection="column"
      px={3}
    >
      <Box flexGrow={1}>
        <Box
          pt={3.6}
          pb={1}
          color={colors.blue[500]}
          aria-label={messages.buttonBack}
        >
          {state !== states.showingLiberate && (
            <ArrowBack
              onClick={() => history.goBack()}
              data-testid="arrow-back"
            />
          )}
          {state === states.showingLiberate && (
            <CloseIcon
              onClick={() => history.goBack()}
              data-testid="close-icon"
            />
          )}
        </Box>

        {state === states.loading && <Loading />}
        {state === states.showingError && (
          <ErrorState errorMessage={errorMessage} />
        )}
        {state === states.nothingFound && <EmptyState />}
        {state === states.showingDispute && (
          <ExpiredConfirmationDetails disputeDetails={disputeDetail} />
        )}
        {state === states.showingOptions && (
          <>
            <Box>
              <Typography variant="h6">{messages.expired.question}</Typography>
            </Box>
            <Box pb={2.5} mt={4} display="column">
              {confirmationExpiredOptions.map(item => (
                <ListItem
                  button
                  divider
                  px={2.5}
                  mt={2.5}
                  pt={2}
                  display="column"
                  alignItems="center"
                  key={item.label}
                  data-testid="solution-options"
                  onClick={() => handleOption(id, item.value)}
                >
                  <Box>
                    <Typography variant="body1">{item.label}</Typography>
                  </Box>
                  <Box flexGrow={1} textAlign="right">
                    <ChevronRightIcon color="primary" />
                  </Box>
                </ListItem>
              ))}
            </Box>
          </>
        )}
        {state === states.showingLiberate && <ExpiredDeliveryLiberate />}
      </Box>
      {state === states.showingDispute && (
        <Box pb={2.5} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            data-testid="want-to-solve"
            onClick={() => setState(states.showingOptions)}
          >
            {messages.expired.buttonText}
          </Button>
        </Box>
      )}
      {state === states.showingLiberate && (
        <Box pb={2.5} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.goBack()}
            fullWidth
          >
            {messages.success.buttonText}
          </Button>
        </Box>
      )}
    </Box>
  );
}
