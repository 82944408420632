import { colors } from '@loggi/mar';
import {
  registerOfferListAccess,
  OFFER_LIST_ACCESS_SOURCE_CARROUSSEL_BUTTON
} from '@loggi/driverapp-routes-list';

import { PAGES } from 'app';
import { ReactComponent as PresentIcon } from 'view/images/present.svg';
import { ReactComponent as WalletIcon } from 'view/images/wallet.svg';
import { ReactComponent as NewsIcon } from 'view/images/news.svg';
import { ReactComponent as AvaliableRoutesIcon } from 'view/images/avaliable_routes.svg';
import { ReactComponent as HelpIcon } from 'view/images/help.svg';
import { ReactComponent as SignupIcon } from 'view/images/signup.svg';
import { ReactComponent as ReadBarcodeIcon } from 'view/images/read_barcode.svg';
import { ReactComponent as DeliveryConfirmationIcon } from 'view/images/ic-delivery-confirmation.svg';
import { ReactComponent as DirectPickupIcon } from 'view/images/direct-pickup.svg';
import { ReactComponent as SettingsIcon } from 'view/images/settings.svg';
import { ReactComponent as LogoutIcon } from 'view/images/logout.svg';
import { ReactComponent as LocationBlueIcon } from 'view/images/location-blue.svg';
import { ReactComponent as LocationWhiteIcon } from 'view/images/location-white.svg';
import { ReactComponent as SOSIcon } from 'view/images/sos.svg';
import { ReactComponent as ImpersonateIcon } from 'view/images/impersonate.svg';
import { isAuthenticatedWithApiKey } from 'operations/auth/credentials';
import { isEmbeddedInDriverAppWithWebHome } from '@loggi/drivers-app-version';
import { openDeliveryConfirmations, openRoute } from '../actions';

export const pageTitle = 'Home';

export const menuConfigError =
  'Opa! Problema na configuração do seu cadastro. Entre em contato com o suporte.';

export const supportLink =
  'https://ajuda.loggi.com/hc/pt-br/categories/115000392707';

export const defaultMenu = [
  'currentItinerary',
  'indicateNow',
  'bankStatement',
  'news',
  'avaliableRoutes',
  'deliveryConfirmations',
  'help',
  'signup',
  'readBarcode',
  'directPickup',
  'settings',
  'logout',
  'sos'
];

export const menuText = {
  currentItinerary: 'Minha rota',
  indicateNow: 'Indique e ganhe',
  bankStatement: 'Extrato',
  news: 'Novidades',
  avaliableRoutes: 'Ofertas disponíveis',
  help: 'Ajuda',
  signup: 'Cadastro',
  readBarcode: 'Leitor de saca',
  directPickup: 'Coletar pacotes',
  deliveryConfirmations: 'Confirmar entregas',
  settings: 'Ajustes',
  logout: 'Sair do aplicativo',
  sos: 'SOS Urgente',
  impersonate: 'Personificar'
};

const getCurrentItineraryInfo = ({ history, driver }) => {
  const hasActivities =
    driver?.pendingActivitiesCount > 0 || driver?.acceptedPickupsCount > 0;

  return {
    style: hasActivities
      ? { background: colors.gradients.sanches, cursor: 'pointer' }
      : { cursor: 'pointer' },
    icon: hasActivities ? LocationWhiteIcon : LocationBlueIcon,
    color: hasActivities ? colors.root[0] : null,
    action: openRoute(history, driver?.itineraryType)
  };
};

export const menuItens = {
  currentItinerary: ({ history, driver }) => {
    return {
      ...getCurrentItineraryInfo({ history, driver }),
      text: menuText.currentItinerary,
      testAction: 'openFlecha'
    };
  },
  impersonate: ({ history, location }) => {
    return {
      text: menuText.impersonate,
      icon: ImpersonateIcon,
      action: () => {
        history.push({
          pathname: PAGES.IMPERSONATE,
          search: location.search
        });
      },
      testAction: 'openImpersonate'
    };
  },
  indicateNow: () => {
    return {
      text: menuText.indicateNow,
      icon: PresentIcon,
      action: () => window.driverAppBridge.openDGDInstructions(),
      testAction: 'openDGDInstructions'
    };
  },
  bankStatement: () => {
    return {
      text: menuText.bankStatement,
      icon: WalletIcon,
      action: () => window.driverAppBridge.openDriverSummary(),
      testAction: 'openDriverSummary'
    };
  },
  news: ({ linkToNewsUrl }) => {
    const validateUrl = /^(https?:\/\/)?([\w-]+\.)+[\w]+(\/[\w-./?%&=]*)?$/;

    if (!linkToNewsUrl || !validateUrl.test(linkToNewsUrl)) return null;

    return {
      text: menuText.news,
      icon: NewsIcon,
      action: () => {
        window.location.href = linkToNewsUrl;
      },
      testAction: 'openNews'
    };
  },
  deliveryConfirmations: ({ history, driver }) => {
    return {
      text: menuText.deliveryConfirmations,
      icon: DeliveryConfirmationIcon,
      action: () => openDeliveryConfirmations(history, driver),
      testAction: 'openDeliveryConfirmations'
    };
  },
  avaliableRoutes: ({ history, location }) => {
    return {
      text: menuText.avaliableRoutes,
      icon: AvaliableRoutesIcon,
      action: () => {
        registerOfferListAccess(OFFER_LIST_ACCESS_SOURCE_CARROUSSEL_BUTTON);
        return history.push({
          pathname: PAGES.OFFER_LIST,
          search: location.search
        });
      },
      testAction: 'openAvailableRoutes'
    };
  },
  help: () => {
    return {
      text: menuText.help,
      icon: HelpIcon,
      action: () => window.open(supportLink),
      testAction: 'openHelp'
    };
  },
  signup: ({ history, location }) => {
    return {
      text: menuText.signup,
      icon: SignupIcon,
      action: () =>
        history.push({
          pathname: PAGES.DRIVER_SIGN_UP,
          search: location.search
        }),
      testAction: 'openDocuments'
    };
  },
  readBarcode: ({ driver, history, location }) => {
    return {
      text: menuText.readBarcode,
      icon: ReadBarcodeIcon,
      testAction: 'openDriveThru',
      action: () =>
        history.push({
          pathname: PAGES.BARCODE_READER,
          search: location.search,
          state: { driver }
        })
    };
  },
  directPickup: ({ history, location, directPickupThroughtDrivers }) => {
    return {
      text: menuText.directPickup,
      icon: DirectPickupIcon,
      action: () => {
        if (directPickupThroughtDrivers) {
          history.push({
            pathname: PAGES.DIRECT_PICKUP,
            search: location.search
          });
        } else {
          window.driverAppBridge.openDirectPickup();
        }
      },
      testAction: 'openDirectPickup'
    };
  },
  settings: () => {
    return {
      text: menuText.settings,
      icon: SettingsIcon,
      action: () => window.driverAppBridge.openConfig(),
      testAction: 'openConfig'
    };
  },
  logout: ({ cognitoSignOut, isCognitoSmsAuthEnabled, history }) => {
    return {
      text: menuText.logout,
      icon: LogoutIcon,
      action: async () => {
        localStorage.clear();
        if (isEmbeddedInDriverAppWithWebHome() && isAuthenticatedWithApiKey()) {
          window.driverAppBridge.logout();
          if (isCognitoSmsAuthEnabled) {
            // If the user is in the cognito/sms page and login via sms,
            //  we need to redirect him to the login page because
            // the driver app will not do it.
            history.push(PAGES.LOGIN);
          }
          return;
        }
        await cognitoSignOut();
      },
      testAction: 'logout'
    };
  },
  sos: ({ driver, history, location }) => {
    return {
      text: menuText.sos,
      icon: SOSIcon,
      bgcolor: colors.red[500],
      color: colors.root[0],
      action: () =>
        history.push({
          pathname: PAGES.EMERGENCY,
          search: location.search,
          state: { driver }
        }),
      testAction: 'openEmergency'
    };
  }
};

export const getMenuItem = (item, info, linkToNewsUrl) => {
  if (!menuItens[item]) return null;
  // Every menu item has access to
  // driver (driver information), history and location
  return menuItens[item](info, linkToNewsUrl);
};
