import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const AutomaticRedirectContext = React.createContext({
  shouldOpenRoute: true,
  openRouteHandler: () => ({})
});

const AutomaticRedirectContextProvider = ({ children }) => {
  const [shouldOpenRoute, setShouldOpenRoute] = useState(true);

  const openRouteHandler = value => {
    setShouldOpenRoute(value);
  };

  return (
    <AutomaticRedirectContext.Provider
      value={{
        shouldOpenRoute,
        openRouteHandler
      }}
    >
      {children}
    </AutomaticRedirectContext.Provider>
  );
};

AutomaticRedirectContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AutomaticRedirectContextProvider;
