import React from 'react';
import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import { ReactComponent as ConnectingBrazilIcon } from 'view/images/connecting_brazil.svg';
import { ReactComponent as OnScreenNotificationsIcon } from 'view/images/on-screen_notifications.svg';
import { ReactComponent as OfferShowcaseIcon } from 'view/images/offer_showcase.svg';
import { ReactComponent as OfferPageIcon } from 'view/images/offer_page.svg';
import { ReactComponent as AcceptingAnOfferIcon } from 'view/images/accepting_an_offer.svg';
import { ReactComponent as LocationBlackIcon } from 'view/images/location-black.svg';
import { ReactComponent as ConnectionBlackIcon } from 'view/images/connection-black.svg';
import { ReactComponent as SmartphoneIcon } from 'view/images/smartphone.svg';
import { ReactComponent as LikeIcon } from 'view/images/like.svg';
import { ReactComponent as AlertIcon } from 'view/images/alert.svg';
import { ReactComponent as CallIcon } from 'view/images/call.svg';
import { ReactComponent as CallTransparentIcon } from 'view/images/call_transparent.svg';
import { ReactComponent as PackageLocationIcon } from 'view/images/package_location.svg';
import { ReactComponent as KindnessIcon } from 'view/images/kindness.svg';

export const text = {
  title: 'você foi habilitado para entregas! 🎉',
  backButton: 'Anterior',
  initialButton: 'Ver como usar o aplicativo',
  nextButton: 'Próximo',
  closeButton: 'Vamos começar',
  backShowcaseButton: 'Voltar para vitrine'
};

export const meiRelation =
  'LAST_MILE_COMPANY_TYPE_LOGGI:DRIVER_COMPANY_RELATION_CONTRACTOR';
const leveRelation =
  'LAST_MILE_COMPANY_TYPE_LEVE:DRIVER_COMPANY_RELATION_COLLABORATOR';
const transpRelation =
  'LAST_MILE_COMPANY_TYPE_CARRIER:DRIVER_COMPANY_RELATION_COLLABORATOR';
const cltRelation =
  'LAST_MILE_COMPANY_TYPE_LOGGI:DRIVER_COMPANY_RELATION_STAFF';

const faqMei = [
  {
    TitleFaq: () => <Box data-testid="faqMeiTitle1"> </Box>,
    TextFaq: () => (
      <Box data-testid="faqMeiText1">
        Antes de aceitar sua primeira oferta vamos apresentar algumas
        informações importantes para usar o aplicativo Loggi no seu dia a dia.
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqMeiTitle2">
        <Box display="flex" justifyContent="center">
          <ConnectingBrazilIcon width="312px" height="181px" />
        </Box>
        <Box
          component="span"
          fontWeight="fontWeightBold"
          fontSize={24}
          fontFamily="Montserrat"
        >
          <p>Conectando o Brasil, levando pacotes de um lugar para outro</p>
        </Box>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqMeiText2" fontSize={16} fontFamily="Montserrat">
        Aqui você encontrará{' '}
        <Box component="span" fontWeight="fontWeightBold">
          ofertas de rota
        </Box>{' '}
        para fazer, de acordo com os seus horários e rotina.
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqMeiTitle3">
        <Box component="span" fontWeight="fontWeightBold" fontSize={24}>
          Encontrando uma oferta
        </Box>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqMeiText3">
        <Box component="span" fontSize={18}>
          As ofertas de rota aparecerão de acordo com sua proximidade e você
          poderá encontrar nos 3 canais:
        </Box>
        <Box>
          <Box component="span" fontWeight="fontWeightBold" fontSize={20}>
            <p>1. Notificações em tela</p>
          </Box>
          <Box display="flex" justifyContent="center">
            <OnScreenNotificationsIcon width="312px" height="346px" />
          </Box>
          <Box component="span" fontSize={18}>
            Com novas ofertas disponíveis tocando em tempo real que você estiver
            ativo.
          </Box>

          <Box component="span" fontWeight="fontWeightBold" fontSize={20}>
            <p>2. Vitrine de ofertas</p>
          </Box>
          <Box display="flex" justifyContent="center">
            <OfferShowcaseIcon width="312px" height="350px" />
          </Box>
          <Box component="span" fontSize={18}>
            Na “Página Inicial” com uma seleção de ofertas em destaque
            disponíveis para você.
          </Box>

          <Box component="span" fontWeight="fontWeightBold" fontSize={20}>
            <p>3. Página de ofertas</p>
          </Box>
          <Box display="flex" justifyContent="center">
            <OfferPageIcon width="312px" height="383px" />
          </Box>
          <Box component="span" fontSize={18}>
            Em ”Ofertas”, com uma maior variedade de rotas para serem aceitas.
          </Box>
        </Box>
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqMeiTitle4" fontWeight="fontWeightBold">
        <AcceptingAnOfferIcon width="102.56px" height="120px" />
        <p>Aceitando uma oferta</p>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqMeiText4" fontSize={18}>
        <Box component="div" pb={14}>
          <p>
            O valor que é pago pelas rotas é definido de acordo com a
            quilometragem a ser percorrida, a quantidade de paradas e as
            características do serviço.
          </p>

          <p>
            Para aceitar uma oferta, fique atento a todas as informações da rota
            e escolha a melhor oferta para hoje ou planeje os próximos dias.
          </p>
        </Box>
        <Box
          component="div"
          alignItems="center"
          pt={2}
          fontSize={18}
          borderTop={1}
          style={{ borderTopColor: '#E6E6E6', borderTopStyle: 'solid' }}
        >
          <Box component="span" fontWeight="fontWeightBold">
            Tem mais dúvidas?
          </Box>{' '}
          Veja mais sobre o tema em{' '}
          <a href="https://ajuda.loggi.com/hc/pt-br/articles/6586635188749-Como-pegar-rotas-">
            ajuda.loggi.com
          </a>
        </Box>
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqMeiTitle5">
        <Box component="span" fontWeight="fontWeightBold" fontSize={24}>
          Dicas para uma boa rota
        </Box>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqMeiText5" fontSize={18}>
        <Box
          component="div"
          pb={2}
          borderBottom={1}
          style={{ borderBottomColor: '#E6E6E6', borderBottomStyle: 'solid' }}
        >
          <Box component="span" display="inline-block" verticalAlign="textTop">
            <p>
              <LocationBlackIcon /> Confira o local de retirada e se há dicas do
              destinatário;
            </p>
          </Box>
        </Box>

        <Box
          component="div"
          pb={2}
          pt={2}
          borderBottom={1}
          style={{ borderBottomColor: '#E6E6E6', borderBottomStyle: 'solid' }}
        >
          <Box component="span" display="inline-block" verticalAlign="textTop">
            <ConnectionBlackIcon /> Criamos uma ordenação de paradas pensando na
            melhor experiência, mas você pode seguir a ordem que for mais
            conveniente;
          </Box>
        </Box>

        <Box
          component="div"
          pb={2}
          pt={2}
          borderBottom={1}
          style={{ borderBottomColor: '#E6E6E6', borderBottomStyle: 'solid' }}
        >
          <Box component="span" display="inline-block" verticalAlign="textTop">
            <SmartphoneIcon /> Utilize o aplicativo para bipar os pacotes e
            adicioná-los na sua rota;
          </Box>
        </Box>

        <Box
          component="div"
          pb={5}
          pt={2}
          borderBottom={1}
          style={{ borderBottomColor: '#E6E6E6', borderBottomStyle: 'solid' }}
        >
          <Box component="span" display="inline-block" verticalAlign="textTop">
            <LikeIcon /> Ao terminar, finalize a rota para adicionar seus ganhos
            ao repasse e aceitar novas ofertas.
          </Box>
        </Box>

        <Box component="div" alignItems="center" pt={5}>
          <Box component="span" fontWeight="fontWeightBold">
            Tem mais dúvidas?
          </Box>{' '}
          Veja mais sobre o tema em{' '}
          <a href="https://ajuda.loggi.com/hc/pt-br/articles/6586811293837-Saiba-como-realizar-entregas-de-sucesso">
            ajuda.loggi.com
          </a>
        </Box>
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqMeiTitle6">
        <Box component="span" fontWeight="fontWeightBold">
          Dicas para uma boa rota
        </Box>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqMeiText6" fontSize={18}>
        <Box component="div" pb={7}>
          <Box
            component="div"
            pb={5}
            pt={2}
            borderBottom={1}
            style={{ borderBottomColor: '#E6E6E6', borderBottomStyle: 'solid' }}
          >
            <Box
              component="span"
              display="inline-block"
              verticalAlign="textTop"
            >
              <AlertIcon /> Caso não consiga realizar alguma parada na sua rota,
              siga as instruções do app para seguir com o procedimento correto;
            </Box>
          </Box>
          <Box component="div" pb={5} pt={5}>
            <Box
              component="span"
              display="inline-block"
              verticalAlign="textTop"
            >
              <CallIcon /> Em casos de necessidade de atendimento especializado,
              clique em <CallTransparentIcon /> para buscar pronto atendimento.
            </Box>
          </Box>
        </Box>

        <Box py={2.5} pt={5.5}>
          <Alert severity="" color="info">
            <ContactSupportOutlinedIcon />
            <AlertTitle>Central de Ajuda</AlertTitle>
            Caso tenha algum problema e precise de suporte, você pode acionar
            nossa Central de ajuda.
          </Alert>
        </Box>
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqMeiTitle7" fontWeight="fontWeightBold" fontSize={24}>
        <Box component="span">Repasses das ofertas</Box>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqMeiText7" fontSize={18}>
        <Box component="div" pt={2} pb={4}>
          <p>
            Os repasses, por padrão, são feitos até o 8º dia útil do mês
            seguinte à realização das rotas. O valor é transferido para a conta
            bancária cadastrada
          </p>

          <p>
            Os repasses podem ser antecipados para serem realizados
            semanalmente, acontecendo sempre na sexta-feira útil seguinte à
            realização das rotas
          </p>

          <p>
            Você pode acompanhar seus ganhos acessando o ”Extrato” diretamente
            pelo aplicativo
          </p>
        </Box>

        <Box
          component="div"
          alignItems="center"
          pt={2}
          borderTop={1}
          style={{ borderTopColor: '#E6E6E6', borderTopStyle: 'solid' }}
        >
          <Box component="span" fontWeight="fontWeightBold">
            Tem mais dúvidas?
          </Box>{' '}
          Veja mais sobre o tema em{' '}
          <a href="https://ajuda.loggi.com/hc/pt-br/articles/4410145099021-Como-funciona-o-repasse-da-Loggi-">
            ajuda.loggi.com
          </a>
        </Box>

        <Box py={2.5} pt={4}>
          <Alert severity="" color="info">
            <MonetizationOnOutlinedIcon />
            <AlertTitle>Repasse semanal</AlertTitle>
            Fique atento às taxas de cobrança por antecipação e escolha a opção
            que melhor se adequa à você no Extrato.
          </Alert>
        </Box>
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqMeiTitle8" fontSize={24}>
        <PackageLocationIcon />
        <Box component="span" fontWeight="fontWeightBold">
          <p>Antes de começar</p>
        </Box>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqMeiText8" fontSize={18}>
        <Box
          component="div"
          alignItems="center"
          pb={25}
          borderBottom={1}
          style={{ borderBottomColor: '#E6E6E6', borderBottomStyle: 'solid' }}
        >
          <p>
            Para conseguir fazer entregas com a Loggi,{' '}
            <Box component="span" fontWeight="fontWeightBold">
              mantenha o GPS sempre ligado.
            </Box>
          </p>
          <p>
            Isso é essencial para podermos mostrar as ofertas da sua região.
            Além disso, o GPS traz segurança para suas rotas.
          </p>
        </Box>
        <Box component="div" alignItems="center" pt={2}>
          <Box component="span" fontWeight="fontWeightBold">
            Tem mais dúvidas?
          </Box>{' '}
          Veja mais sobre o tema em{' '}
          <a href="https://ajuda.loggi.com/hc/pt-br/articles/4410122115981-Quando-eu-aceitar-uma-oferta-onde-eu-pego-o-pacote-">
            ajuda.loggi.com
          </a>
        </Box>
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqMeiTitle9" fontSize={24}>
        <KindnessIcon />
        <Box component="span" fontWeight="fontWeightBold">
          <p style={{ color: '#FFFFFF' }}>Tudo certo!</p>
        </Box>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqMeiText9" fontSize={18}>
        <Box component="div" alignItems="center">
          <p style={{ color: '#FFFFFF' }}>
            Agora você já pode começar a utilizar o aplicativo e fazer as suas
            rotas.
          </p>
          <p style={{ color: '#FFFFFF' }}>
            Caso queira ver novamente, clique em ver guia de uso.
          </p>
        </Box>
      </Box>
    )
  }
];

const faqLeve = [
  {
    TitleFaq: () => (
      <Box data-testid="faqLeveTitle1">
        Marque o{' '}
        <Box component="span" fontWeight="fontWeightBold">
          status
        </Box>{' '}
        da entrega
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqLeveText1">
        Lembre-se de marcar o status dos pacotes durante a rota como: entregues
        e não entregues.
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqLeveTitle2">
        Entregas{' '}
        <Box component="span" fontWeight="fontWeightBold">
          concluídas
        </Box>{' '}
        com sucesso
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqLeveText2">
        Lembre-se de atualizar suas entregas feitas no mesmo dia. É importante
        indicar os motivos de impedimento para que sua entrega seja concluída
        com sucesso.
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqLeveTitle3">
        Mantenha o status{' '}
        <Box component="span" fontWeight="fontWeightBold">
          sincronizado
        </Box>
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqLeveText3">
        Para sincronizar o status do seu pacote durante o uso do app sem
        internet, é só ir em &ldquo;atualizar&rdquo; ou &ldquo;enviar
        novamente&rdquo;. Agora, é só clicar em &ldquo;atualizar lista&rdquo;
        para que os pacotes entregues não apareçam mais na sua lista.
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqLeveTitle4">
        <Box component="span" fontWeight="fontWeightBold">
          Aceite mais de uma oferta
        </Box>{' '}
        por vez
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqLeveText4">
        Você pode aceitar mais de uma rota de coletas, é só ir na tela inicial
        em &ldquo;ofertas disponíveis&rdquo; e aceitar outras rotas.
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqLeveTitle5">
        <Box component="span" fontWeight="fontWeightBold">
          Encontre
        </Box>{' '}
        ofertas disponíveis
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqLeveText5">
        Na home do app você pode ver suas ofertas disponíveis clicando em
        &ldquo;Ofertas disponíveis&rdquo; ou fazer uma busca em &ldquo;buscar
        ofertas&rdquo;.
      </Box>
    )
  }
];

const faqTransportadora = [
  {
    TitleFaq: () => (
      <Box data-testid="faqTransportadoraTitle1">
        Marque o{' '}
        <Box component="span" fontWeight="fontWeightBold">
          status
        </Box>{' '}
        da entrega
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqTransportadoraText1">
        Não esqueça de marcar o status dos pacotes durante a rota: entregues e
        não entregues. É importante indicar os motivos que impediram de fazer a
        sua entrega para que ela seja concluída com sucesso.
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqTransportadoraTitle2">
        Finalize a{' '}
        <Box component="span" fontWeight="fontWeightBold">
          rota
        </Box>{' '}
        no mesmo dia
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqTransportadoraText2">
        Primeiro, confira se marcou corretamente o status de todos os pacotes
        entregues e não entregues. Clique em &ldquo;finalizar a rota&rdquo;
        somente depois que todos os pacotes forem entregues e/ou devolvidos na
        agência.
      </Box>
    )
  }
];

const faqClt = [
  {
    TitleFaq: () => (
      <Box data-testid="faqCltTitle1">
        Marque o{' '}
        <Box component="span" fontWeight="fontWeightBold">
          status
        </Box>{' '}
        da entrega
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqCltText1">
        Não esqueça de marcar o status dos pacotes durante a rota: entregues e
        não entregues. É importante indicar os motivos que impediram de fazer a
        sua entrega para que ela seja concluída com sucesso.
      </Box>
    )
  },
  {
    TitleFaq: () => (
      <Box data-testid="faqCltTitle2">
        Finalize a{' '}
        <Box component="span" fontWeight="fontWeightBold">
          rota
        </Box>{' '}
        no mesmo dia
      </Box>
    ),
    TextFaq: () => (
      <Box data-testid="faqCltText2">
        Primeiro, confira se marcou corretamente o status de todos os pacotes
        entregues e não entregues. Clique em &ldquo;finalizar a rota&rdquo;
        somente depois que todos os pacotes forem entregues e/ou devolvidos na
        agência.
      </Box>
    )
  }
];

const onboardText = relation => {
  return {
    [meiRelation]: faqMei,
    [leveRelation]: faqLeve,
    [transpRelation]: faqTransportadora,
    [cltRelation]: faqClt
  }[relation];
};

export default onboardText;
