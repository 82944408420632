export const messages = {
  title: 'Confirmação de entrega',
  buttonBack: 'Voltar',
  status: {
    pending: 'Aguardando confirmação',
    solved: 'Resolvida',
    waiting_analysis: 'Aguardando análise',
    confirmation_not_accepted: 'Confirmação não aceita',
    expired: 'Prazo expirado',
    solved_after_expired: 'Resolvida depois de expirada'
  },
  confirmation: {
    solved: 'Confirmado em ',
    pending: 'Confirmar até '
  },
  detail: {
    title: 'Detalhes da entrega',
    answerUntil: 'Responda até ',
    toAcceptNewOffers: ' para voltar a aceitar novas ofertas.',
    whoReceive: 'Quem recebeu',
    deliveryInformation: 'Informações da entrega',
    buttonText: 'Fiz a entrega'
  },
  receipt: {
    title: 'Vá até o local e confirme que a entrega foi feita',
    description: {
      firstLine:
        'Você pode entrar em contato com o destinatário e confirmar que ele está com o pacote.',
      secondLine:
        'No local, peça os dados do destinatário e confirme a entrega, ou recupere o pacote e devolva-o para uma Base Loggi.'
    },
    recipientData: 'Dados do destinatário',
    buttonText: 'Ir para lá'
  },
  proximity: {
    youAre: 'Você está a',
    meters: 'metros',
    deliveryPlace: 'do local de entrega.',
    alertDescription:
      'O ideal é estar perto do endereço do destinatário para confirmar que você tentou entregar os pacotes.',
    continue: 'Continuar mesmo assim'
  },
  expired: {
    title: 'O prazo para confirmar essa entrega expirou',
    buttonText: 'Quero resolver',
    question: 'Por que a entrega não foi confirmada?',
    liberateTitle: 'Pronto! Você já pode ver novas ofertas',
    liberateText:
      'Se atente aos prazos de confirmação. Perder o prazo novamente pode te impossibilitar de fazer entregas.',
    titleWithoutResolution:
      'Atenção! O tempo para responder às confirmações de entrega é de 48 horas.',
    descriptionWithoutResolution:
      'Para resolver a confirmação responda a mensagem que enviamos no seu e-mail com o motivo do atraso.'
  },
  confirm: {
    title: 'Peça o nome e documento de quem recebeu o pacote',
    buttonText: 'Continuar para assinatura',
    fields: {
      name: 'Nome Completo',
      document: {
        label: 'Nº Documento',
        error: 'Documento inválido'
      },
      comments: 'Deixe um comentário sobre essa confirmação.'
    },
    documentType: {
      unknown: {
        value: '1'
      },
      cpf: {
        name: 'CPF',
        value: '2'
      },
      cnpj: {
        name: 'CNPJ',
        value: '3'
      }
    },
    sendingText: 'Enviando...'
  },
  signature: {
    title: 'Colete a assinatura de quem recebeu o pacote',
    signatureAreaLabel: 'Peça para %s assinar aqui',
    buttonRedoSignature: 'Refazer',
    buttonSetState: 'Enviar'
  },
  empty: {
    title: 'Nenhuma confirmação por enquanto',
    description:
      'Pode ser que um pacote entregue precise ser confirmado com o destinatário. Quando isso acontecer, a gente te avisa por aqui.'
  },
  error: {
    title: 'Aconteceu um erro por aqui.',
    description: 'Pode ser que o servidor esteja com problemas no momento.',
    buttonText: 'Tentar novamente'
  },
  documentError: {
    title: 'Opa, tivemos um erro na verificação dos documentos',
    description:
      'Tente novamente e se não conseguir anote o nome completo e documento do recebedor.',
    buttonText: 'Tentar novamente'
  },
  success: {
    title: 'Recebemos sua confirmação de entrega!',
    description:
      'A partir de agora é com a gente! Vamos analisar os dados e, caso esteja tudo certo, sua confirmação será validada.',
    buttonText: 'Voltar para entregas'
  },
  fields: {
    error: 'Preencha o campo corretamente.'
  }
};

export const fieldIsValid = field => field && field.trim().length > 1;
export const unresolvedStates = [
  'waiting_analysis',
  'confirmation_not_accepted'
];

export const unresolvedStatesMessage = {
  waiting_analysis: {
    title: 'Estamos avaliando sua confirmação.',
    description:
      'Enviamos um link para o destinatário confirmar que o pacote foi recebido!'
  },
  confirmation_not_accepted: {
    title: 'Confirmação não aceita',
    description:
      'Enviamos instruções para o seu e-mail. Confira para resolver essa questão.'
  },
  buttonText: 'Voltar para entregas'
};

export const confirmationExpiredOptions = [
  { value: 'didnt_go_to_local', label: 'Não queria ir até o local' },
  {
    value: 'expired',
    label: 'Não deu tempo de ir até o local'
  },
  {
    value: 'package_returned_base',
    label: 'Encontrei o pacote e levei para base'
  },
  { value: 'other', label: 'Outro' }
];
