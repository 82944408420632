import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { PAGES } from 'app';
import { colors } from '@loggi/mar';
import { getLastKnownLocation } from 'operations/geolocation';

import { getDisputeDetail } from 'operations/disputes';
import getFirebaseAnalyticsData from 'view/utils';
import sendEventToAnalytics from 'operations/firebase';
import {
  analyticsCategories,
  analyticsEventNames
} from 'operations/firebase/constants';
import computeDistanceBetween from 'geolib/es/getDistance';
import Loading from './loading';
import EmptyState from './empty-state';
import ErrorState from './error-state';
import Detail from './detail';
import Receipt from './receipt';
import Proximity from './proximity';
import {
  messages,
  unresolvedStates,
  unresolvedStatesMessage
} from './constants';
import { shouldNotifyDriverAboutDistance } from '../../utils';

export default function DeliveryConfirmationDetailsPage() {
  const history = useHistory();
  const { location } = window;
  const [disputeDetail, setDisputeDetail] = useState({});
  const driver = history.location.state;
  const { disputeStatus } = disputeDetail;
  const [distance, setDistance] = useState({});

  const states = {
    loading: 'loading',
    showingDispute: 'showingDispute',
    showingReceipt: 'showingReceipt',
    showingProximity: 'showingProximity',
    nothingFound: 'nothingFound',
    showingError: 'showingError',
    showingSignature: 'showingSignature'
  };

  const [state, setState] = useState(states.loading);
  const [errorMessage, setErrorMessage] = useState('');
  const { id } = useParams();

  const firebaseAnalyticsData = getFirebaseAnalyticsData(
    driver,
    analyticsCategories.disputeEvidences,
    messages.title
  );

  useEffect(() => {
    setState(states.loading);
    getDisputeDetail(id)
      .then(response => {
        if (Object.keys(response).length > 0) {
          setState(states.showingDispute);
          setDisputeDetail(response);
        } else {
          setState(states.nothingFound);
        }
      })
      .catch(error => {
        const { errors } = error;
        const { message } = errors && errors[0] ? errors[0] : '';

        setErrorMessage(message);
        setState(states.showingError);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    const eventData = Object.assign(firebaseAnalyticsData);

    if (state === states.showingDispute) {
      eventData.category = analyticsCategories.disputeDetails;
      history.goBack();
    } else {
      setState(states.showingDispute);
    }

    sendEventToAnalytics(analyticsEventNames.clickBackButton, eventData);
  };

  const canShowButton = () => {
    return Boolean(
      state === states.showingDispute &&
        disputeDetail.displayDisputeButton &&
        disputeDetail.disputeStatus === 'pending'
    );
  };

  const deliveryDone = () => {
    setState(states.showingReceipt);
    const eventData = Object.assign(firebaseAnalyticsData, {
      category: analyticsCategories.disputeDetails
    });

    sendEventToAnalytics(
      analyticsEventNames.clickDisputeButtonDeliveryDone,
      eventData
    );
  };

  const receiptDone = () => {
    sendEventToAnalytics(
      analyticsEventNames.clickDisputeButtonGoThere,
      firebaseAnalyticsData
    );

    const driverLastKnownLocation = getLastKnownLocation();

    const driverCoords = {
      lat: driverLastKnownLocation?.latitude,
      lng: driverLastKnownLocation?.longitude
    };

    let destinationCoords = {
      lat: null,
      lng: null
    };
    // The disputeDetail proto has latitude and longitude as type double which always
    // returns 0.0 even if the original values are Null. Since this coords represent a
    // empty point at sea we are considering this point as null.
    if (disputeDetail?.latitude !== 0.0 && disputeDetail?.longitude !== 0.0) {
      destinationCoords = {
        lat: disputeDetail?.latitude,
        lng: disputeDetail?.longitude
      };
    }

    if (
      destinationCoords.lat &&
      destinationCoords.lng &&
      shouldNotifyDriverAboutDistance(driverCoords, destinationCoords)
    ) {
      setDistance(computeDistanceBetween(driverCoords, destinationCoords));
      setState(states.showingProximity);
    } else {
      history.push(
        {
          pathname: PAGES.DELIVERY_CONFIRMATION_CONFIRM.replace(
            ':id',
            disputeDetail.disputeId
          ),
          search: location.search
        },
        { driver }
      );
    }
  };

  return (
    <Box
      bgcolor={colors.root[0]}
      display="flex"
      minHeight="100%"
      flexDirection="column"
      px={3}
    >
      <Box flexGrow={1}>
        <Box
          pt={3.5}
          pb={1}
          color={colors.blue[500]}
          aria-label={messages.buttonBack}
        >
          <ArrowBack onClick={() => handleGoBack()} data-testid="arrow-back" />
        </Box>
        {state === states.loading && <Loading />}
        {state === states.showingDispute && <Detail data={disputeDetail} />}
        {state === states.showingReceipt && <Receipt />}
        {state === states.showingProximity && <Proximity distance={distance} />}
        {state === states.showingError && (
          <ErrorState errorMessage={errorMessage} />
        )}
        {state === states.nothingFound && <EmptyState />}
      </Box>
      {canShowButton() && (
        <Box pb={2.5} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={() => deliveryDone()}
            data-testid="delivery-done"
          >
            {messages.detail.buttonText}
          </Button>
        </Box>
      )}
      {unresolvedStates.includes(disputeStatus) && (
        <Box pb={2.5} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={() => history.goBack()}
            data-testid="back-index"
          >
            {unresolvedStatesMessage.buttonText}
          </Button>
        </Box>
      )}
      {state === states.showingReceipt && (
        <Box pb={2.5} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            data-testid="receipt-done"
            onClick={() => receiptDone()}
          >
            {messages.receipt.buttonText}
          </Button>
        </Box>
      )}
      {state === states.showingProximity && (
        <Box pb={2.5} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            data-testid="proximity-done"
            fullWidth
            onClick={() => {
              sendEventToAnalytics(
                analyticsEventNames.clickDisputeButtonConfirmAnyway,
                firebaseAnalyticsData
              );

              history.push(
                {
                  pathname: PAGES.DELIVERY_CONFIRMATION_CONFIRM.replace(
                    ':id',
                    disputeDetail.disputeId
                  ),
                  search: location.search
                },
                { driver }
              );
            }}
          >
            {messages.proximity.continue}
          </Button>
        </Box>
      )}
    </Box>
  );
}
