import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch } from 'react-router-dom';

const Routes = props => {
  const { children } = props;
  return (
    <BrowserRouter>
      <Switch>{children}</Switch>
    </BrowserRouter>
  );
};

Routes.propTypes = {
  children: PropTypes.node
};

Routes.defaultProps = {
  children: null
};

export default Routes;
