const messages = {
  offerAvaliable: 'Silenciar app',
  offerBusy: 'Reativar',
  offerNone: 'Busque por ofertas disponíveis.',
  offerNoneSubtitle:
    'Aqui embaixo você pode buscar novas ofertas para fazer enquanto espera.',
  offerSearch: 'Buscar ofertas',
  offerBusyNotificationSnackbar:
    'Você ativou o modo silencioso e não vai receber notificações.',
  finishItineraryTitle: 'Sempre finalize sua rota',
  finishItinerarySubtitle:
    'Para aceitar novas ofertas e receber por elas, finalize a sua rota ativa.'
};

export default messages;
