import React from 'react';
import { Box, Typography, Dialog, Button, Grid } from '@material-ui/core';

import { ReactComponent as LoggiIcon } from 'view/images/loggi.svg';
import { ReactComponent as UpdateDriverAppIcon } from 'view/images/update_app.svg';
import {
  isEmbeddedInDriverAppWithWebHome,
  hasValidDriverAppVersion
} from '@loggi/drivers-app-version';

export const linkToOpenInPlayStore = 'market://details?id=com.loggi.driverapp';
export const linkToAppInPlayStore =
  'https://play.google.com/store/apps/details?id=com.loggi.driverapp';

const minVersionWithPlayStoreHook = 485;

export const messages = {
  title: 'Atualização necessária',
  subtitle: 'Você precisa atualizar o aplicativo para fazer novas rotas.',
  button: 'Atualizar'
};

export const handleOpenPlayStore = event => {
  event.preventDefault();
  const link =
    isEmbeddedInDriverAppWithWebHome() &&
    hasValidDriverAppVersion(minVersionWithPlayStoreHook)
      ? linkToOpenInPlayStore
      : linkToAppInPlayStore;
  window.open(link);
};

export default function ErrorUpdateDriverAppDialog() {
  return (
    <Dialog fullScreen open data-testid="dialog-error-update-driver-app">
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          pt={4.5}
          px={3.5}
          pb={2.5}
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Grid container justify="space-between" alignItems="center">
            <LoggiIcon height="34px" />
          </Grid>
        </Box>
        <Box pt={6} px={3} width="100%">
          <UpdateDriverAppIcon data-testid="errorRegistrationIcon" />
        </Box>
        <Box pt={3} px={2.5} flexGrow={1}>
          <Typography variant="h5">{messages.title}</Typography>
          <Box>{messages.subtitle}</Box>
        </Box>
        <Box p={3} width="100%">
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={handleOpenPlayStore}
          >
            {messages.button}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
