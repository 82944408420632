import React from 'react';
import theme from '@loggi/mar';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  AmplifyAuthProvider,
  AmplifyConfigure
} from '@loggi/authentication-lib';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import FirebaseConfigProvider from '@loggi/firebase-feature-switches';
import { setWatchPosition } from 'operations/geolocation';
import { CssBaseline } from '@material-ui/core';
import registerServiceWorker from 'serviceWorkerRegistration';
import firebaseInit from 'operations/config/firebase';
import config from './aws-exports';
import 'operations/config/sentry';
import './configuration/i18n-init';
import App from './app';

firebaseInit();
setWatchPosition();
AmplifyConfigure(config);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <FirebaseConfigProvider>
      <AmplifyAuthProvider>
        <SnackbarProvider
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
          autoHideDuration={6000}
          maxSnack={3}
        >
          <CssBaseline />
          <App />
        </SnackbarProvider>
      </AmplifyAuthProvider>
    </FirebaseConfigProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

registerServiceWorker();
