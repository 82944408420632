import React from 'react';

import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { messages, unresolvedStatesMessage } from './constants';
import { ReactComponent as NotAcceptedIcon } from './images/not-accepted-state.svg';
import { ReactComponent as WaitingAnalysisIcon } from './images/waiting-analysis-state.svg';

export default function DetailStates({ status }) {
  return (
    <Box flexGrow={1} textAlign="center" flexDirection="column" display="flex">
      <Box>
        {status === 'confirmation_not_accepted' && <NotAcceptedIcon />}
        {status === 'waiting_analysis' && <WaitingAnalysisIcon />}
      </Box>
      <Box mb={4}>
        <Typography variant="h5">
          {unresolvedStatesMessage[status].title}
        </Typography>
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body2">
          {unresolvedStatesMessage[status].description}
        </Typography>
      </Box>
      {status === 'waiting_analysis' && (
        <Box flexGrow={1} p={2}>
          <Typography variant="body2">
            {messages.success.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

DetailStates.propTypes = {
  status: PropTypes.string.isRequired
};
