import lwApi from 'operations/config/api';

/**
 * Performs a API request to emergency
 *
 * @returns {Promise}
 */
export default function registerEmergency(url) {
  return lwApi()
    .url(url)
    .post({})
    .json();
}
