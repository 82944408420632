import React, { useState } from 'react';
import { Box, Chip, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { colors, pxToRem } from '@loggi/mar';
import { isAuthenticatedWithCognito } from 'operations/auth/credentials';
import featureSwitches from 'operations/feature-switches';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import CognitoDialog from './dialog';

export const config = {
  text: 'Associe sua conta Google agora!'
};

export default function CognitoAlert() {
  const [
    openWarningAssociateCognitoDialog,
    setOpenWarningAssociateCognitoDialog
  ] = useState(false);
  const {
    state: { authenticatedUser: user }
  } = useAmplifyAuth();
  const enableBannerCognito = useFeatureSwitch(
    featureSwitches.enableBannerCognito
  );

  if (isAuthenticatedWithCognito(user) || !enableBannerCognito) {
    return <></>;
  }

  const toggleOpenWarningAssociateCognitoDialog = () => {
    setOpenWarningAssociateCognitoDialog(!openWarningAssociateCognitoDialog);
  };
  return (
    <Box>
      {openWarningAssociateCognitoDialog && (
        <CognitoDialog onClose={toggleOpenWarningAssociateCognitoDialog} />
      )}
      <Box mt={1} width="100%">
        <Chip
          style={{
            backgroundColor: colors.yellow[50],
            color: colors.smoke[900],
            display: 'flex',
            justifyContent: 'space-between',
            padding: `${pxToRem(26)} ${pxToRem(8)}`
          }}
          label={
            <Box display="flex" alignItems="center">
              <Typography
                variant="h5"
                style={{ color: colors.yellow[700], paddingRight: pxToRem(8) }}
              >
                ●
              </Typography>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                {config.text}
              </Typography>
            </Box>
          }
          onClick={toggleOpenWarningAssociateCognitoDialog}
          onDelete={toggleOpenWarningAssociateCognitoDialog}
          deleteIcon={
            <ArrowForwardIosIcon
              fontSize="small"
              style={{ color: colors.smoke[900] }}
            />
          }
        />
      </Box>
    </Box>
  );
}
