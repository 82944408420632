import React, { useState } from 'react';
import { Redirect, Route as PublicRoute } from 'react-router-dom';
import DriverAuthenticationRoutes from '@loggi/driver-authentication';
import DriverSignUp from '@loggi/driver-signup';
import {
  OffersPageV2,
  BarcodeReaderPage,
  OfferScreen
} from '@loggi/driverapp-routes-list';
import Flecha from '@loggi/flecha';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import AutomaticRedirectContextProvider from 'contexts/automatic-redirect-context';
import { ImpersonationForm } from '@loggi/authentication/src/screens';
import ImpersonationBanner from '@loggi/authentication/src/components/impersonation-banner';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import ShowcaseSurvey from 'view/pages/home/offer-showcase/survey';
import PermissionNotification from 'view/pages/home/permissions-notification';
import {
  shouldNotififyDriverAboutPermission,
  setParamsLastPermissionNotification
} from 'view/pages/home/permissions-notification/check-notification';
import useSendUserDataToDriverApp from 'hooks/use-update-driverapp-data';
import isAuthenticatedWithCognitoOrApiKey, {
  isFsCognitoAuth,
  isFsCognitoSmsAuth,
  getImpersonateWithApiKey
} from 'operations/auth/credentials';
import PrivateRoute from 'routes/private-route';
import LoadingHome from 'view/pages/loading-home';
import Signature from 'view/pages/delivery-confirmation/signature';
import sendEventToAnalytics from 'operations/firebase';
import { analyticsEventNames } from 'operations/firebase/constants';
import DeliveryNotFoundPage from 'view/pages/delivery-not-found';
import DeliveryNotFoundDetailsPage from 'view/pages/delivery-not-found/details';
import featureSwitches from 'operations/feature-switches';
import Routes from './routes';
import DeliveryConfirmationPage from './view/pages/delivery-confirmation';
import DeliveryConfirmationConfirmPage from './view/pages/delivery-confirmation/confirm';
import DeliveryConfirmationDetailsPage from './view/pages/delivery-confirmation/details';
import DeliveryConfirmationExpiredPage from './view/pages/delivery-confirmation/expired';
import EmergencyWrapper from './view/pages/emergency';
import HomePage from './view/pages/home';
import ImpersonateApiKey from './view/pages/impersonate-apikey';

import 'typeface-open-sans';
import './view/styles/index.css';

export const PAGES = {
  ROOT: '/',
  LOGIN: '/entrar',
  HOME: '/home',
  DRIVER_SIGN_UP: '/criar_conta',
  FLECHA: '/atividades',
  DELIVERY_CONFIRMATION: '/entregas',
  DELIVERY_CONFIRMATION_DETAILS: '/entregas/detalhe/:id',
  DELIVERY_CONFIRMATION_SIGNATURE: '/entregas/confirmar/assinatura/:id',
  DELIVERY_CONFIRMATION_CONFIRM: '/entregas/confirmar/:id',
  DELIVERY_CONFIRMATION_EXPIRED: '/entregas/expirado/:id',
  DELIVERY_NOT_FOUND: '/entregas-nao-encontradas',
  DELIVERY_NOT_FOUND_DETAILS: '/entregas-nao-encontradas/detalhe/:id',
  EMERGENCY: '/emergency',
  SHOWCASE_SURVEY: '/pesquisa-showcase',
  IMPERSONATE: '/representar',
  IMPERSONATE_APIKEY: '/representar-apikey',
  OFFER_LIST: '/ofertas',
  BARCODE_READER: '/leitor-de-saca',
  OFFER_SCREEN: '/ofertas/:offerId',
  DIRECT_PICKUP: '/atividades/coleta-direta/',
  OFFER_NOTIFICATION: '/atividades/oferta-notification'
};

function App() {
  const {
    state: { isImpersonation, authenticatedUser },
    signOut
  } = useAmplifyAuth();
  const [checkPermissions, setCheckPermissions] = useState(true);

  useSendUserDataToDriverApp(authenticatedUser);

  const signOutImpersonateApiKey = () => {
    localStorage.clear();
    window.driverAppBridge = undefined;
    window.location.href = process.env.REACT_APP_DRIVERS_URL;
  };

  const isEnabledSms = () => {
    return isFsCognitoSmsAuth() && !isFsCognitoAuth();
  };

  const enablePermissionNotification = useFeatureSwitch(
    featureSwitches.enablePermissionNotification
  );

  const maxPermissionNotificationFS = useFeatureSwitch(
    featureSwitches.maxPermissionNotification
  );

  const daysToPermissionNotificationFS = useFeatureSwitch(
    featureSwitches.daysToPermissionNotification
  );

  const enableFixSignInWithAnotherAccount = useFeatureSwitch(
    featureSwitches.enableFixSignInWithAnotherAccount
  );

  if (
    checkPermissions &&
    shouldNotififyDriverAboutPermission(
      enablePermissionNotification,
      maxPermissionNotificationFS,
      daysToPermissionNotificationFS
    )
  ) {
    const notificationPermissionCallback = () => {
      const isNotificationPermissionDisabled = window.driverAppBridge?.isNotificationPermissionDisabled?.();
      const eventName = isNotificationPermissionDisabled
        ? analyticsEventNames.notAllowPermissionNotification
        : analyticsEventNames.allowPermissionNotification;
      sendEventToAnalytics(eventName);
      setParamsLastPermissionNotification();
      setCheckPermissions(false);
    };
    const handleAllowPermission = () => {
      window.driverAppBridgePermissionCallback = notificationPermissionCallback;
      window.driverAppBridge?.openNotificationSettings?.(
        'driverAppBridgePermissionCallback'
      );
    };

    sendEventToAnalytics(analyticsEventNames.viewPermissionNotification);

    return (
      <PermissionNotification
        handleOnAllowClick={handleAllowPermission}
        handleOnNotAllowClick={notificationPermissionCallback}
      />
    );
  }

  return (
    <AutomaticRedirectContextProvider>
      {isImpersonation && (
        <ImpersonationBanner name={authenticatedUser?.name} signOut={signOut} />
      )}
      {getImpersonateWithApiKey()?.apikey && (
        <ImpersonationBanner name="ApiKey" signOut={signOutImpersonateApiKey} />
      )}
      <Routes>
        <PublicRoute exact path={PAGES.ROOT} component={LoadingHome} />

        <PublicRoute
          exact
          path={PAGES.LOGIN}
          component={() => (
            <DriverAuthenticationRoutes
              createAccountPath={PAGES.DRIVER_SIGN_UP}
              onSuccessLoginRedirectPath={`${PAGES.HOME}/${
                window.location.search
              }`}
              enableSmsAuth={isEnabledSms()}
              sendEventToAnalytics={sendEventToAnalytics}
              enableFixSignInWithAnotherAccount={
                enableFixSignInWithAnotherAccount
              }
            />
          )}
        />

        <PublicRoute
          exact
          path={PAGES.DRIVER_SIGN_UP}
          component={DriverSignUp}
        />

        <PrivateRoute
          exact
          path={PAGES.HOME}
          component={() => <HomePage enableSmsAuth={isFsCognitoSmsAuth()} />}
        />

        <PrivateRoute path={PAGES.FLECHA} component={Flecha} />

        <PrivateRoute exact path={PAGES.OFFER_LIST} component={OffersPageV2} />

        <PrivateRoute
          exact
          path={PAGES.IMPERSONATE}
          component={ImpersonationForm}
          isSupportRoute
        />

        <PrivateRoute
          exact
          path={PAGES.IMPERSONATE_APIKEY}
          component={ImpersonateApiKey}
          isSupportRoute
        />

        <PrivateRoute
          exact
          path={PAGES.DELIVERY_NOT_FOUND}
          component={DeliveryNotFoundPage}
        />

        <PrivateRoute
          exact
          path={PAGES.DELIVERY_NOT_FOUND_DETAILS}
          component={DeliveryNotFoundDetailsPage}
        />

        <PrivateRoute
          exact
          path={PAGES.DELIVERY_CONFIRMATION}
          component={DeliveryConfirmationPage}
        />

        <PrivateRoute
          exact
          path={PAGES.DELIVERY_CONFIRMATION_DETAILS}
          component={DeliveryConfirmationDetailsPage}
        />

        <PrivateRoute
          exact
          path={PAGES.DELIVERY_CONFIRMATION_SIGNATURE}
          component={Signature}
        />

        <PrivateRoute
          exact
          path={PAGES.DELIVERY_CONFIRMATION_CONFIRM}
          component={DeliveryConfirmationConfirmPage}
        />

        <PrivateRoute
          exact
          path={PAGES.DELIVERY_CONFIRMATION_EXPIRED}
          component={DeliveryConfirmationExpiredPage}
        />

        <PrivateRoute
          exact
          path={PAGES.EMERGENCY}
          component={EmergencyWrapper}
        />

        <PrivateRoute
          exact
          path={PAGES.SHOWCASE_SURVEY}
          component={ShowcaseSurvey}
        />

        <PrivateRoute
          exact
          path={PAGES.BARCODE_READER}
          component={BarcodeReaderPage}
        />

        <PrivateRoute path={PAGES.OFFER_SCREEN} component={OfferScreen} />

        <PublicRoute path="*">
          <Redirect
            to={
              isAuthenticatedWithCognitoOrApiKey(authenticatedUser)
                ? `${PAGES.HOME}/${window.location.search}`
                : PAGES.LOGIN
            }
          />
        </PublicRoute>
      </Routes>
    </AutomaticRedirectContextProvider>
  );
}

export default App;
