import * as Sentry from '@sentry/browser';

const env = process.env.REACT_APP_ENV;

export const errorsToIgnore = [
  /**
   * This error is thrown by the browser's ResizeObserver API. In Flecha,
   * this is triggered by react-virtuoso in the AssignmentList. For more
   * context, see this PR: https://github.com/loggi/ui/pull/4798
   */
  /ResizeObserver loop limit exceeded/i,
  /**
   * This error is the same as the above, but it occours on Firefox browser
   */
  /ResizeObserver loop completed with undelivered notifications/i
];

export function initSentry(overrides = {}) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_VERSION,
    environment: env,
    beforeSend: (event, hint) => {
      const message = hint?.originalException?.message;
      // see: https://docs.sentry.io/platforms/node/guides/aws-lambda/configuration/filtering/hints/#hints-for-events
      const syntheticExceptionMessage = hint?.syntheticException?.message;
      const shouldIgnoreError = errorsToIgnore.reduce(
        (shouldIgnore, errorToIgnore) =>
          shouldIgnore ||
          message?.match(errorToIgnore) ||
          syntheticExceptionMessage?.match(errorToIgnore),
        false
      );
      if (shouldIgnoreError) {
        return null;
      }
      return event;
    },
    ignoreErrors: errorsToIgnore,
    ...overrides
  });
}

if (env === 'production' || env === 'staging') {
  initSentry();
}
