import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'app';
import { Box, Typography, Chip } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { colors } from '@loggi/mar';
import { getDisputes } from 'operations/disputes';
import showSnackbar from 'shared/snackbar';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

import getFirebaseAnalyticsData from 'view/utils';
import sendEventToAnalytics from 'operations/firebase';
import {
  analyticsCategories,
  analyticsEventNames
} from 'operations/firebase/constants';
import EmptyState from './empty-state';
import Loading from './loading';
import { messages } from './constants';

export default function DeliveryConfirmationPage() {
  const history = useHistory();
  const { location } = window;
  const { enqueueSnackbar } = useSnackbar();
  const [disputes, setDisputes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const driver = history.location.state;

  const firebaseAnalyticsData = getFirebaseAnalyticsData(
    driver,
    analyticsCategories.disputeList,
    messages.title
  );

  const disputeStatus = {
    confirmationNotAccepted: 'confirmation_not_accepted',
    expired: 'expired',
    pending: 'pending',
    solved: 'solved',
    solved_after_expired: 'solved_after_expired'
  };

  const handleGoBack = () => {
    sendEventToAnalytics(
      analyticsEventNames.clickBackButton,
      firebaseAnalyticsData
    );
    history.goBack();
  };

  useEffect(() => {
    setIsLoading(true);
    getDisputes({})
      .then(response => {
        setDisputes(response?.disputes || []);
      })
      .catch(error => {
        const { errors } = error;
        const { message } = errors && errors[0] ? errors[0] : {};

        showSnackbar({
          message,
          variant: 'error',
          enqueueSnackbar
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (disputes.length) {
      sendEventToAnalytics(
        analyticsEventNames.hasDisputes,
        firebaseAnalyticsData
      );
    }
  }, [disputes, firebaseAnalyticsData]);

  const getConfirmationDate = dispute => {
    const displayDate =
      dispute.disputeStatus === 'solved' ||
      dispute.disputeStatus === 'solved_after_expired'
        ? dispute.solvedTime
        : dispute.disputeDeadlineTime;

    return (
      displayDate &&
      format(new Date(displayDate), 'dd MMM, HH:MM', { locale: pt })
    );
  };

  const isConfirmationWithProblem = dispute =>
    dispute.disputeStatus === disputeStatus.confirmationNotAccepted ||
    dispute.disputeStatus === disputeStatus.expired;

  const isConfirmedOrPending = dispute =>
    dispute.disputeStatus === disputeStatus.solved ||
    dispute.disputeStatus === disputeStatus.pending ||
    dispute.disputeStatus === disputeStatus.solved_after_expired;

  return (
    <Box
      bgcolor={colors.root[0]}
      display="flex"
      minHeight="100%"
      flexDirection="column"
    >
      <Box flexGrow={1}>
        <Box
          px={2.5}
          pt={3.6}
          pb={1}
          color={colors.blue[500]}
          aria-label={messages.buttonBack}
        >
          <ArrowBack onClick={handleGoBack} data-testid="arrow-back" />
        </Box>

        <Box px={2.5} color={colors.root[900]}>
          <Typography variant="h5">{messages.title}</Typography>
        </Box>
        {isLoading && <Loading />}
        {!isLoading && !!disputes.length && (
          <Box borderBottom={1} borderColor={colors.smoke} pb={2.5}>
            {disputes.map(dispute => (
              <Box
                key={dispute.disputeId}
                px={2.5}
                mt={2.5}
                pt={2}
                borderColor={colors.smoke[200]}
                borderTop={1}
                color={colors.smoke[800]}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => {
                  sendEventToAnalytics(
                    analyticsEventNames.clickDisputeDetails,
                    firebaseAnalyticsData
                  );

                  if (dispute.disputeStatus === disputeStatus.expired) {
                    history.push({
                      pathname: PAGES.DELIVERY_CONFIRMATION_EXPIRED.replace(
                        ':id',
                        dispute.disputeId
                      ),
                      search: location.search
                    });
                  } else {
                    history.push(
                      {
                        pathname: PAGES.DELIVERY_CONFIRMATION_DETAILS.replace(
                          ':id',
                          dispute.disputeId
                        ),
                        search: location.search
                      },
                      { driver }
                    );
                  }
                }}
              >
                <Box>
                  <Box>
                    {isConfirmationWithProblem(dispute) && (
                      <Chip
                        size="small"
                        style={{ backgroundColor: colors.red[100] }}
                        label={messages.status[dispute.disputeStatus]}
                      />
                    )}
                    {!isConfirmationWithProblem(dispute) && (
                      <Chip
                        size="small"
                        label={messages.status[dispute.disputeStatus]}
                      />
                    )}
                  </Box>
                  <Box py={1.5} color={colors.blue[500]}>
                    <Typography variant="body2">
                      {isConfirmedOrPending(dispute) && (
                        <Box component="span" fontWeight="fontWeightBold">
                          {messages.confirmation[dispute.disputeStatus]}
                        </Box>
                      )}
                      {isConfirmedOrPending(dispute) &&
                        getConfirmationDate(dispute)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {dispute.packageCompanyName}
                    </Typography>
                    <Typography variant="body2">{dispute.address}</Typography>
                  </Box>
                </Box>
                <Box>
                  <ChevronRightIcon color="primary" />
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {!isLoading && !disputes.length && <EmptyState />}
      </Box>
    </Box>
  );
}
