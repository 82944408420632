import React, { useCallback, useEffect, useState } from 'react';
import { colors } from '@loggi/mar';
import { Box, Typography } from '@material-ui/core';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { getAcceptedPickups } from '@loggi/flecha';
import useAutomaticRedirectToRoute from 'hooks/use-automatic-redirect-to-route';
import { useSnackbar } from 'notistack';
import firebase from 'firebase/app';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useHistory } from 'react-router-dom';
import { PAGES } from 'app';
import PropTypes from 'prop-types';

import getAppVersion, {
  hasValidDriverAppVersion,
  hasNewDriverAppVersion
} from '@loggi/drivers-app-version';
import {
  DeviceValidationError,
  deviceValidation,
  updateCurrentUserInfo,
  getCurrentUserInfo
} from '@loggi/driver-authentication';
import { NETWORK_ERROR } from 'operations/config/api/http-message';
import getDriver from 'operations/get-driver';
import {
  getLastUpdateDriverInfo,
  removeRedirectToFlecha,
  setLastUpdateDriverInfo,
  shouldRedirectToFlecha
} from 'operations/config/storage';
import sendOnboardUTCDateToInsider from 'operations/insider';
import updateDriverAvailabilityRequest from 'operations/update-driver-availability';
import showSnackbar from 'shared/snackbar';
import DisableNotificationDrawer, {
  STATE_ENUM
} from 'view/molecules/disable-notification-drawer';
import HomeSkeleton from 'view/molecules/home-skeleton';
import RetryPage from 'view/pages/retry';
import { ReactComponent as NoNetworkIcon } from 'view/images/wi-fi-no.svg';
import { ReactComponent as RefreshIcon } from 'view/images/refresh.svg';
import sendEventToAnalytics from 'operations/firebase';
import { analyticsEventNames } from 'operations/firebase/constants';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import featureSwitches from 'operations/feature-switches';
import {
  HeaderComponent,
  PendingDisputesComponent,
  PendingDisputesModality2Component
} from './components';
import { messages, itineraryTypes } from './constants';
import errorHandling from './error/handling';
import {
  ErrorRegistrationAlert,
  ErrorRegistrationDialog
} from './error/registration';
import { driverStatus } from './error/registration/constants';
import ErrorUpdateDriverAppDialog from './error/update-driver-app';
import OfferShowcaseComponent from './offer-showcase';
import MenuContainer from './menu';
import { defaultMenu, menuItens } from './menu/constants';
import messagesOffer from './offer-showcase/constants';
import OnboardingDialog from './onboarding';
import NewDriverAppVersionDrawer from './error/update-driver-app/new-version';
import CognitoAlert from './warning/cognito/alert';

const stateEnum = {
  initial: 'initial',
  unableToGetDriver: 'unableToGetDriver',
  error: 'error',
  loading: 'loading',
  retrying: 'retrying',
  success: 'success'
};

function getDriverInfoValues(driverInfo) {
  const showMenuError = !(driverInfo && driverInfo.menu);
  const availableForNotifications =
    driverInfo?.availableForNotifications || false;
  const showRegistrationError =
    driverInfo?.operationalStatus !== driverStatus.licensed;

  return {
    ...driverInfo,
    availableForNotifications,
    menu: driverInfo?.menu || defaultMenu,
    showMenuError,
    showRegistrationError,
    pendingActivitiesCount: driverInfo?.pendingActivitiesCount || 0,
    acceptedPickupsCount: driverInfo?.acceptedPickupsCount || 0,
    itineraryType:
      driverInfo?.itineraryType || itineraryTypes.itinerary_type_pro
  };
}

export default function HomePage({ enableSmsAuth }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isOffline, setIsOffline] = useState(false);
  const [state, setState] = useState(stateEnum.initial);
  const [statePickup, setStatePickup] = useState(stateEnum.initial);
  const [driverInfo, setDriverInfo] = useState(getDriverInfoValues({}));
  const [
    isAvailableForNotifications,
    setIsAvailableForNotifications
  ] = useState(true);
  const [isFirstUnableNotifications, setIsFirstUnableNotifications] = useState(
    true
  );
  const [showErrorConfigMenu, setShowErrorConfigMenu] = useState(false);
  const [
    shouldOpenErrorRegistrationDialog,
    setShouldOpenErrorRegistrationDialog
  ] = useState(false);
  const [deviceRegistrationError, setDeviceRegistrationError] = useState(null);
  const [drawerState, setDrawerState] = useState(STATE_ENUM.OPEN);
  const history = useHistory();
  const {
    state: { authenticatedUser },
    signOut: cognitoSignOut
  } = useAmplifyAuth();
  const enableModality2Disputes = useFeatureSwitch(
    featureSwitches.enableModality2Disputes
  );
  const isSendDataToInsiderEnabled = useFeatureSwitch(
    featureSwitches.enableSendDataToInsider
  );

  useAutomaticRedirectToRoute(driverInfo);

  const retrying = () => {
    setState(stateEnum.retrying);
    setStatePickup(stateEnum.initial);
  };

  const handleGetDriverError = useCallback(
    (error, currentDriverInfo) => {
      if (error.code === NETWORK_ERROR.code && currentDriverInfo?.id) {
        setState(stateEnum.success);
        setIsOffline(true);
        return;
      }

      if (error.code === 403) {
        const { logout } = menuItens;
        logout({
          cognitoSignOut,
          isCognitoSmsAuth: enableSmsAuth,
          history
        }).action(); // NOSONAR
        return;
      }

      setState(stateEnum.unableToGetDriver);
      const { errors } = error;
      const { message } = errors && errors[0] ? errors[0] : {};

      showSnackbar({
        message,
        variant: 'error',
        enqueueSnackbar
      });
    },
    [cognitoSignOut, enableSmsAuth, history, enqueueSnackbar]
  );

  useEffect(() => {
    if (state === stateEnum.initial) {
      const traceDeviceValidation = firebase
        .performance()
        .trace('device_validation');

      traceDeviceValidation.start();
      deviceValidation()
        .catch(error => {
          if (error.code === 401) {
            const deviceValidationError = error.errors[0] || {};
            setDriverInfo(
              getDriverInfoValues({ email: deviceValidationError?.email })
            );
            setDeviceRegistrationError(deviceValidationError?.code);
          }
        })
        .finally(() => {
          traceDeviceValidation.stop();
        });
    }
  }, [state]);

  useEffect(() => {
    if (state !== stateEnum.retrying && state !== stateEnum.initial) return;

    setIsOffline(false);

    setState(stateEnum.loading);
    const trace = firebase.performance().trace('get_driver_info');
    trace.start();

    /*
     * This is needed to not have problem with both services trying to update driverInfo with a old version of itself
     * Because all useEffect triggered before driverInfo change will finish with its old values
     */
    let currentDriverInfo = driverInfo;
    let acceptedPickupsCount = 0;

    getDriver()
      .then(response => {
        currentDriverInfo = {
          ...getDriverInfoValues(response),
          acceptedPickupsCount
        };

        if (window.navigator.onLine) {
          const lastUpdateDate = new Date();
          setLastUpdateDriverInfo(`${lastUpdateDate.toISOString()}`);
        } else {
          setIsOffline(true);
        }

        setDriverInfo(currentDriverInfo);
        updateCurrentUserInfo(currentDriverInfo);

        setShowErrorConfigMenu(currentDriverInfo.showMenuError);
        setIsAvailableForNotifications(
          currentDriverInfo.availableForNotifications
        );
        setShouldOpenErrorRegistrationDialog(
          currentDriverInfo.showRegistrationError
        );

        setState(stateEnum.success);
      })
      .catch(error => {
        if (!currentDriverInfo || !currentDriverInfo?.id) {
          currentDriverInfo = {
            ...getCurrentUserInfo(),
            acceptedPickupsCount
          };
          setDriverInfo(currentDriverInfo);
        }

        handleGetDriverError(error, currentDriverInfo);
      })
      .finally(() => {
        trace.stop();
      });

    if (statePickup === stateEnum.initial) {
      const traceAcceptedPickups = firebase
        .performance()
        .trace('accepted_pickups');

      traceAcceptedPickups.start();

      getAcceptedPickups()
        .then(response => {
          acceptedPickupsCount = response?.assignmentsWaypoints?.length || 0;
          setDriverInfo({
            ...currentDriverInfo,
            acceptedPickupsCount
          });
        })
        .catch(error => {
          const responseError =
            error?.status === 410 && error?.text
              ? JSON.parse(error?.text)
              : null;
          const notificationDriver = responseError?.errors?.[0]?.[0];
          if (notificationDriver?.address) {
            const notificationType = notificationDriver?.type;
            const eventAddress = notificationDriver?.address;
            const deadline = notificationDriver?.deadline;
            history.push(PAGES.FLECHA);
            history.push({
              pathname: PAGES.OFFER_NOTIFICATION,
              state: {
                notificationType,
                eventAddress,
                deadline
              }
            });
          } else {
            showSnackbar({
              message: `Não foi possível verificar suas coletas. Erro: ${JSON.stringify(
                error
              )}`,
              variant: 'error',
              enqueueSnackbar
            });
          }
        })
        .finally(() => {
          setStatePickup(stateEnum.success);
          traceAcceptedPickups.stop();
        });
    }
  }, [
    enqueueSnackbar,
    state,
    statePickup,
    handleGetDriverError,
    driverInfo,
    history
  ]); // NOSONAR

  const flipAvailabilityForNotifications = () => {
    const nextAvailibilityValue = !isAvailableForNotifications;
    setState(stateEnum.loading);
    updateDriverAvailabilityRequest({
      driverId: driverInfo?.id,
      available: nextAvailibilityValue
    })
      .then(() => {
        setState(stateEnum.success);
        setIsAvailableForNotifications(nextAvailibilityValue);
        if (!nextAvailibilityValue && !isFirstUnableNotifications) {
          showSnackbar({
            message: messagesOffer.offerBusyNotificationSnackbar,
            variant: 'success',
            enqueueSnackbar
          });
        }
      })
      .catch(error => {
        errorHandling(enqueueSnackbar)(error);
        setState(stateEnum.error);
      });
  };

  if (deviceRegistrationError) {
    return (
      <DeviceValidationError
        errorDevice={deviceRegistrationError}
        email={driverInfo?.email}
      />
    );
  }

  if (
    state === stateEnum.loading ||
    state === stateEnum.initial ||
    statePickup === stateEnum.initial
  ) {
    return <HomeSkeleton />;
  }
  if (state === stateEnum.unableToGetDriver) {
    return <RetryPage onRetry={retrying} />;
  }

  if (!hasValidDriverAppVersion(driverInfo?.driverAppMinVersion)) {
    return <ErrorUpdateDriverAppDialog />;
  }

  // this mean that the driver finished his/her activity and now is ready to get a new one
  if (
    !driverInfo?.pendingActivitiesCount &&
    !driverInfo?.acceptedPickupsCount &&
    !shouldRedirectToFlecha()
  ) {
    removeRedirectToFlecha();
  }

  sendEventToAnalytics(analyticsEventNames.home, {
    id: driverInfo?.id,
    email: driverInfo?.email
  });

  if (isSendDataToInsiderEnabled && driverInfo?.onboardTime) {
    sendOnboardUTCDateToInsider(driverInfo?.onboardTime);
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {isOffline && (
        <Box
          px={3}
          py={2}
          width="100%"
          style={{ backgroundColor: colors.smoke[900], color: colors.root[0] }}
          onClick={retrying}
        >
          <Typography variant="subtitle2">
            <NoNetworkIcon width="16px" height="16px" />
            <Box ml={1.5} component="span">
              {messages.offline}
            </Box>
          </Typography>
          <Typography variant="caption">
            {getLastUpdateDriverInfo() && (
              <>
                {messages.lastUpdate}{' '}
                {format(
                  new Date(getLastUpdateDriverInfo()),
                  'dd/MM/yyyy HH:MM:ss',
                  {
                    locale: pt
                  }
                )}{' '}
                <RefreshIcon width="12px" height="12px" />
              </>
            )}
          </Typography>
        </Box>
      )}
      <Box
        pt={4.5}
        px={2.5}
        pb={4}
        width="100%"
        display="flex"
        border={1}
        borderLeft={0}
        borderRight={0}
        bordertop={0}
        borderColor="info.light"
        flexDirection="column"
      >
        <HeaderComponent
          flipAvailabilityForNotifications={flipAvailabilityForNotifications}
          isAvailableForNotifications={isAvailableForNotifications}
          onlySupport={driverInfo?.onlySupport}
        />

        <Box mt={3}>
          <Typography variant="subtitle1">
            {messages.hello} <b>{driverInfo?.firstName}.</b>
          </Typography>
        </Box>
        <Typography variant="body1">
          <Box component="span" color={colors.smoke[700]}>
            {authenticatedUser?.email}
          </Box>
        </Typography>

        <CognitoAlert data-testid="cognito-alert-associate-account" />
        <PendingDisputesComponent driverInfo={driverInfo} />
        {enableModality2Disputes &&
          driverInfo.companyRelation ===
            'DRIVER_COMPANY_RELATION_CONTRACTOR' && (
            <PendingDisputesModality2Component driverInfo={driverInfo} />
          )}
        <ErrorRegistrationAlert driverInfo={driverInfo} />

        <MenuContainer
          showErrorConfigMenu={showErrorConfigMenu}
          driverInfo={driverInfo}
        />
      </Box>

      <OfferShowcaseComponent driverInfo={driverInfo} />

      <Box py={4} color={colors.smoke[700]} data-testid="app-version">
        {getAppVersion()}
      </Box>

      {!isAvailableForNotifications &&
        !driverInfo?.onlySupport &&
        isFirstUnableNotifications && (
          <DisableNotificationDrawer
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            buttonClick={() => {
              setIsFirstUnableNotifications(false);
              setDrawerState(STATE_ENUM.CLOSED);
            }}
          />
        )}
      <ErrorRegistrationDialog
        driverInfo={driverInfo}
        shouldOpenErrorRegistrationDialog={shouldOpenErrorRegistrationDialog}
      />

      <OnboardingDialog driverInfo={driverInfo} />

      <NewDriverAppVersionDrawer
        hasNewDriverAppVersion={hasNewDriverAppVersion()}
      />
    </Box>
  );
}
HomePage.propTypes = {
  enableSmsAuth: PropTypes.bool
};

HomePage.defaultProps = {
  enableSmsAuth: false
};
