import wretch from 'wretch';
import { getAuthorizationTokens } from '@loggi/authentication-lib';
import { getCredentials } from 'operations/auth/credentials';
import { isEmbeddedInDriverApp } from '@loggi/drivers-app-version';
import {
  NETWORK_ERROR,
  PARSE_ERROR,
  translateStatusCode
} from './http-message';

/* eslint-disable no-param-reassign */
export async function getHeaders(headers) {
  const currentUser = getCredentials();
  const { idToken } = await getAuthorizationTokens();

  if (isEmbeddedInDriverApp() && currentUser) {
    headers.Authorization = currentUser;
  } else if (idToken) {
    headers.Authorization = `Bearer ${idToken}`;
  }

  headers.appVersion = process.env.REACT_APP_VERSION;
  headers.service = 'drivers';

  return headers;
}

export const headerMiddleware = next => async (url, opts) => {
  const headers = opts?.headers;

  const optsHeader = {
    ...opts,
    headers: await getHeaders(headers)
  };

  return next(url, optsHeader);
};

export default function lwApi() {
  return wretch()
    .url(`${process.env.REACT_APP_LOGGI_WEB_URL}`)
    .content('application/json')
    .middlewares([headerMiddleware], true)
    .errorType('text')
    .catcher('Error', err =>
      Promise.reject(translateStatusCode(err.response.status, err.message))
    )
    .catcher('FetchError', () => Promise.reject(PARSE_ERROR))
    .catcher('__fromFetch', () => Promise.reject(NETWORK_ERROR));
}
