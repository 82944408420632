import { getAuthorizationTokens } from '@loggi/authentication-lib';
import { isEmbeddedInDriverApp } from '@loggi/drivers-app-version';
import { useEffect } from 'react';
import {
  isFsCognitoAuth,
  isFsCognitoSmsAuth
} from 'operations/auth/credentials';

const getDriverIdFromCognitoUser = user =>
  /* eslint-disable camelcase */
  JSON.parse(user?.['custom:access'])?.loggi_user_id;

const sendUserDataToDriverApp = async user => {
  const { idToken } = await getAuthorizationTokens();

  window.driverAppBridge?.setCognitoToken?.(idToken);
  window.driverAppBridge?.setDriverId?.(getDriverIdFromCognitoUser(user));
  window.driverAppBridge?.updatePushToken?.();
  window.driverAppBridge?.getOrderAllocated?.();
};

export default function useSendUserDataToDriverApp(user) {
  const fsCognitoAuth = isFsCognitoAuth();
  const fsCognitoSmsAuth = isFsCognitoSmsAuth();
  const isCognitoAuth = fsCognitoAuth || fsCognitoSmsAuth;
  const isValidUser = user?.['custom:access'];

  useEffect(() => {
    if (
      isCognitoAuth &&
      isValidUser &&
      getDriverIdFromCognitoUser(user) &&
      isEmbeddedInDriverApp()
    ) {
      sendUserDataToDriverApp(user);
    }
    /* eslint-disable-next-line */
  }, [user?.at_hash, isCognitoAuth]);
}
