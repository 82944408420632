import React, { useState } from 'react';
import {
  Box,
  Button,
  RadioGroup,
  TextField,
  Typography,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { formatCpfCnpj, onlyDigits } from 'shared/formatters/cpf-cnpj';
import { validateCnpjCpf } from 'shared/validators/cnpj-cpf';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { fieldIsValid, messages } from './constants';

export const DISPUTE_CONFIRM_FORM_ID = 'dispute-confirm-form';
export default function ConfirmForm({ onSubmit, disputeId }) {
  const initialFormData = {
    disputeId,
    protocolRecipientName: '',
    protocolRecipientDocument: '',
    protocolRecipientDocumentType: messages.confirm.documentType.cpf.value,
    protocolNotes: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const errors = Object.values(formErrors);
  const hasErrors = !errors.length || Object.values(formErrors).some(Boolean);
  const isFilled = Object.values(formData).every(Boolean);
  const handleTyping = (value, field, format) => {
    setFormData(_form => {
      return {
        ..._form,
        [field]: format
          ? format(value, formData.protocolRecipientDocumentType)
          : value
      };
    });
  };

  const validateField = (validation, field) => {
    if (formData[field]) {
      setFormErrors(_errors => ({
        ..._errors,
        [field]: !validation(
          formData[field],
          formData.protocolRecipientDocumentType
        )
      }));
    }
  };

  const handleDocumentType = e => {
    setFormData(_form => {
      return {
        ..._form,
        protocolRecipientDocumentType: e
      };
    });
  };
  const prepareFormData = () => {
    formData.protocolRecipientDocument = onlyDigits(
      formData.protocolRecipientDocument
    );
    formData.protocolRecipientDocumentType = Number(
      formData.protocolRecipientDocumentType
    );
  };

  const handleSubmit = e => {
    prepareFormData();
    e.preventDefault();
    onSubmit(formData);
  };

  const nameInput = {
    protocolRecipientName: {
      label: messages.confirm.fields.name,
      error: messages.fields.error,
      validate: fieldIsValid
    }
  };

  const documentAndNotesIputs = {
    protocolRecipientDocument: {
      label: messages.confirm.fields.document.label,
      error: messages.confirm.fields.document.error,
      validate: validateCnpjCpf,
      format: formatCpfCnpj
    },
    protocolNotes: {
      label: messages.confirm.fields.comments,
      error: messages.fields.error,
      validate: fieldIsValid,
      multiline: true
    }
  };

  const inputFactory = ([key, input]) => (
    <Box key={key} mt={2} mb={2}>
      <TextField
        label={input.label}
        variant="outlined"
        name={key}
        InputLabelProps={formData[key] ? { shrink: true } : {}}
        onBlur={() => validateField(input.validate, key)}
        value={formData[key]}
        onChange={e => handleTyping(e.target.value, key, input.format)}
        error={Boolean(formData[key] && formErrors[key])}
        helperText={formData[key] && formErrors[key] && input.error}
        multiline={input.multiline}
        rows={input.multiline && '6'}
        fullWidth
      />
    </Box>
  );

  return (
    <form
      noValidate
      onSubmit={handleSubmit}
      data-testid="dispute-form"
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        color: colors.root[900]
      }}
    >
      <Box>
        <Typography variant="h5">{messages.confirm.title}</Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Typography variant="subtitle1">{messages.confirm.subtitle}</Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2">
          {messages.confirm.description}
        </Typography>
      </Box>
      <Box>{Object.entries(nameInput).map(inputFactory)}</Box>
      <Box>
        <RadioGroup
          value={formData.protocolRecipientDocumentType}
          onChange={e => handleDocumentType(e.target.value)}
          row
        >
          <FormControlLabel
            value={messages.confirm.documentType.cpf.value}
            control={<Radio />}
            label={messages.confirm.documentType.cpf.name}
          />
          <FormControlLabel
            value={messages.confirm.documentType.cnpj.value}
            control={<Radio />}
            label={messages.confirm.documentType.cnpj.name}
          />
        </RadioGroup>
      </Box>
      <Box flexGrow={1}>
        {Object.entries(documentAndNotesIputs).map(inputFactory)}
      </Box>
      <Box>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          data-testid={DISPUTE_CONFIRM_FORM_ID}
          type="submit"
          disabled={
            hasErrors ||
            !isFilled ||
            !validateCnpjCpf(
              formData.protocolRecipientDocument,
              formData.protocolRecipientDocumentType
            )
          }
          fullWidth
        >
          {messages.confirm.buttonText}
        </Button>
      </Box>
    </form>
  );
}

ConfirmForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disputeId: PropTypes.string.isRequired
};
