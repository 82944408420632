import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import ExclamationIcon from '@material-ui/icons/Error';

import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

import { messages, unresolvedStates } from './constants';
import DetailStates from './detail-states';

export default function Detail({ data }) {
  const getConfirmationDate = deadlineTime => {
    return (
      deadlineTime &&
      format(new Date(deadlineTime), 'dd/MM - HH:MM', { locale: pt })
    );
  };

  const isDisputeUnresolved = unresolvedStates.includes(data?.disputeStatus);

  if (isDisputeUnresolved) {
    return <DetailStates status={data.disputeStatus} />;
  }

  return (
    <>
      <Box color={colors.root[900]}>
        <Typography variant="h5">{messages.detail.title}</Typography>
      </Box>
      <Box>
        <Box key={data.disputeId} mt={2.5} color={colors.smoke[800]}>
          <Box py={1.5}>
            <Typography variant="h6" fontWeight="fontWeightBold">
              {data.packageCompanyName}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">{data.address}</Typography>
          </Box>
          <Box my={3}>
            <Typography variant="body2">
              {messages.detail.whoReceive}
            </Typography>
            <Box my={0.5}>
              <Typography variant="subtitle2">{data.taskReceiver}</Typography>
            </Box>
          </Box>
          {data.taskNotes && (
            <Box mt={3}>
              <Typography variant="body2">
                {messages.detail.deliveryInformation}
              </Typography>
              <Box my={0.5}>
                <Typography variant="subtitle2">{data.taskNotes}</Typography>
              </Box>
            </Box>
          )}
          {data.disputeStatus === 'pending' && (
            <Box
              my={2}
              py={1}
              pl={1}
              borderRadius="10px"
              bgcolor={colors.yellow[50]}
              color={colors.smoke[900]}
              display="flex"
              alignItems="center"
            >
              <ExclamationIcon
                style={{ color: colors.yellow[500] }}
                fontSize="small"
              />
              <Box flexGrow={1} p={1}>
                {messages.detail.answerUntil}
                <Box component="span" fontWeight={600}>
                  {getConfirmationDate(data.disputeDeadlineTime)}
                </Box>
                {messages.detail.toAcceptNewOffers}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

Detail.propTypes = {
  data: PropTypes.shape.isRequired
};
