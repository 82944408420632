import React from 'react';
import { Box } from '@material-ui/core';
import { colors } from '@loggi/mar';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import { PAGES } from 'app';
import { messages } from './constants';
import ConfirmForm from './confirm-form';

export const DISPUTE_CONFIRM_HISTORY_BACK_ID = 'dispute-confirm-history-back';

export default function DeliveryConfirmationConfirmPage() {
  const history = useHistory();
  const { location } = window;
  const { id } = useParams();

  const onSubmit = data => {
    history.push({
      pathname: PAGES.DELIVERY_CONFIRMATION_SIGNATURE.replace(':id', id),
      state: data,
      search: location.search
    });
  };

  return (
    <Box
      bgcolor={colors.root[0]}
      px={3}
      pb={2}
      display="flex"
      flexDirection="column"
      minHeight="100%"
    >
      <Box
        pt={3.5}
        pb={1}
        color={colors.blue[500]}
        aria-label={messages.buttonBack}
      >
        <ArrowBack
          onClick={() => history.goBack()}
          data-testid={DISPUTE_CONFIRM_HISTORY_BACK_ID}
        />
      </Box>

      <ConfirmForm onSubmit={onSubmit} disputeId={id} />
    </Box>
  );
}
