import React from 'react';
import DriversEmergency from '@loggi/drivers-emergency';
import { useHistory, useLocation } from 'react-router-dom';
import registerEmergency from 'operations/drivers-emergency';

export const companyRelation = {
  collaborator: 'DRIVER_COMPANY_RELATION_COLLABORATOR',
  contractor: 'DRIVER_COMPANY_RELATION_CONTRACTOR',
  staff: 'DRIVER_COMPANY_RELATION_STAFF'
};

export default function EmergencyWrapper() {
  const history = useHistory();
  const location = useLocation();
  const driver = location?.state?.driver;

  return (
    <DriversEmergency
      canNotifyLoggi={driver?.companyRelation === companyRelation.contractor}
      notifyLoggi={registerEmergency}
      goBack={() => history.goBack()}
    />
  );
}
