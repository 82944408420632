import lwApi from 'operations/config/api';

/**
 * Performs a API request to getDisputes
 *
 * @returns {Promise}
 */
export function getDisputes({ modality = null, status = null }) {
  const params = {};

  if (modality) {
    params.modality = modality;
  }

  if (status) {
    params.status = status;
  }

  return lwApi()
    .url('/security/disputes')
    .query(params)
    .get()
    .json();
}

export function getDisputeDetail(id) {
  return lwApi()
    .url(`/security/dispute_details/${id}`)
    .get()
    .json();
}

export function confirmDispute(data) {
  return lwApi()
    .url(`/security/delivery-confirmation`)
    .post(data)
    .json();
}

export function updateExpiredDispute(id, data) {
  return lwApi()
    .url(`/security/expired-dispute/${id}`)
    .put(data)
    .res();
}

export default {
  getDisputes,
  getDisputeDetail,
  confirmDispute,
  updateExpiredDispute
};
