import lwApi from 'operations/config/api';
import geolocation from '../geolocation';

/**
 * Performs a API request to getDriver
 *
 * @returns {Promise}
 */

export const driverInfoUrl = '/last-mile/v1/driver-info';

export default async function getDriver() {
  const { latitude, longitude } = await geolocation();
  const driverInfoParams =
    latitude && longitude ? `?lat=${latitude}&lng=${longitude}` : '';

  return lwApi()
    .url(`${driverInfoUrl}${driverInfoParams}`)
    .get()
    .json();
}
