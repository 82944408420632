import React, { useState } from 'react';
import { Box, Typography, Dialog, Button, Divider } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { add, parseISO } from 'date-fns';

import {
  shouldOpenOnboardingDialog,
  setOpenOnboardingDialog
} from 'operations/config/storage';
import { driverInfoPropType } from 'shared/prop-type/index';
import sendEventToAnalytics from 'operations/firebase';
import onboardText, { text } from './constants';
import { driverStatus } from '../error/registration/constants';

export default function OnboardingDialog({ driverInfo }) {
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [isFirstTimeOpen, setIsFirstTimeOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const isFirstStep = activeStep === 0;
  const [currentPage, setCurrentPage] = useState(0);

  const {
    title,
    backButton,
    nextButton,
    initialButton,
    closeButton,
    backShowcaseButton
  } = text;

  if (!driverInfo?.companyType) {
    return <></>;
  }

  const {
    firstName,
    driverProfilePictureUrl,
    companyType,
    companyRelation,
    operationalStatus,
    onboardTime
  } = driverInfo;

  const onboardTimestamp = parseISO(onboardTime);
  const isDriverNotLicensed = operationalStatus !== driverStatus.licensed;
  const isDriverOnboardedMoreThanMonth =
    new Date() > add(onboardTimestamp, { months: 1 });

  if (
    isDriverNotLicensed ||
    isDriverOnboardedMoreThanMonth ||
    !shouldOpenOnboardingDialog()
  ) {
    return <></>;
  }

  const relation = `${companyType}:${companyRelation}`;
  const faqSteps = onboardText(relation);
  const { TitleFaq, TextFaq } = faqSteps[activeStep];

  const firebaseAnalyticsData = {
    driver_id: driverInfo.id,
    page_title: 'Onboarding',
    relation
  };

  if (isFirstTimeOpen) {
    sendEventToAnalytics('onboarding_show', firebaseAnalyticsData);
    setIsFirstTimeOpen(false);
  }

  const handleOnCloseClick = event => {
    event.preventDefault();
    sendEventToAnalytics('onboarding_close', firebaseAnalyticsData);
    setOpenOnboardingDialog(false);
    setIsDialogOpen(false);
  };

  const handleOnFinishClick = event => {
    event.preventDefault();
    sendEventToAnalytics('onboarding_finish', firebaseAnalyticsData);
    setOpenOnboardingDialog(false);
    setIsDialogOpen(false);
  };

  const handleOnNextClick = event => {
    event.preventDefault();
    sendEventToAnalytics('onboarding_next', firebaseAnalyticsData);
    setActiveStep(activeStep + 1);
    setCurrentPage(currentPage + 1);
  };

  const handleOnBackClick = event => {
    event.preventDefault();
    sendEventToAnalytics('onboarding_back', firebaseAnalyticsData);
    setActiveStep(activeStep - 1);
    setCurrentPage(currentPage - 1);
  };

  const nextStepButton = () => {
    if (currentPage === 0) {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          onClick={handleOnNextClick}
          style={{
            border: `1px solid ${colors.bassis[700]}`,
            background: colors.bassis[700],
            color: colors.root[0],
            borderRadius: `10px`
          }}
        >
          {initialButton}
        </Button>
      );
    }
    if (activeStep < faqSteps.length - 1) {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          onClick={handleOnNextClick}
          style={{
            border: `1px solid ${colors.bassis[700]}`,
            background: colors.bassis[700],
            color: colors.root[0],
            borderRadius: `10px`
          }}
        >
          {nextButton}
        </Button>
      );
    }
    if (currentPage === 8) {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          onClick={handleOnFinishClick}
          style={{
            border: `1px solid ${colors.root[0]}`,
            background: colors.bassis[700],
            color: colors.root[0],
            borderRadius: `10px`
          }}
        >
          {backShowcaseButton}
        </Button>
      );
    }
    return (
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={handleOnCloseClick}
        style={{
          background: colors.gradients.bassis,
          borderRadius: `10px`
        }}
      >
        {closeButton}
      </Button>
    );
  };

  const previousButton = (
    <Box width="35%" mr={2}>
      <Button
        variant="text"
        size="large"
        data-testid="button-backButton"
        fullWidth
        disabled={isFirstStep}
        onClick={handleOnBackClick}
        style={{
          color: isFirstStep ? colors.root[100] : colors.bassis[700],
          border: isFirstStep ? 'none' : `1px solid ${colors.bassis[700]}`,
          borderRadius: `10px`
        }}
      >
        {backButton}
      </Button>
    </Box>
  );

  return (
    <Dialog
      fullScreen
      open={isDialogOpen}
      data-testid="dialog-onboarding"
      PaperProps={{
        elevation: 1,
        style: {
          padding: 0,
          background: currentPage === 8 ? '#006AFF' : 'white'
        }
      }}
    >
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          pt={3.5}
          px={3}
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          <Box component="span" fontWeight="fontWeightBold">
            {currentPage === 0 || currentPage === 8
              ? ''
              : `${currentPage} de 7`}
          </Box>
          <Button
            data-testid="dialogOnboardingCloseIcon"
            onClick={handleOnCloseClick}
            type="button"
            tabIndex={0}
            style={{
              cursor: 'pointer',
              color: colors.bassis[700]
            }}
          >
            Pular
          </Button>
        </Box>
        {isFirstStep && (
          <>
            <Box p={3} display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h6">
                  <Box fontWeight="fontWeightBold">{firstName},</Box>
                </Typography>
                <Typography variant="subtitle1">
                  <Box>{title}</Box>
                </Typography>
              </Box>
              <Box ml={2} display="flex" alignItems="center">
                <img
                  alt="Foto de perfil"
                  src={driverProfilePictureUrl}
                  style={{
                    width: '64px',
                    height: '64px',
                    objectFit: 'cover',
                    borderRadius: '50%'
                  }}
                />
              </Box>
            </Box>

            <Box px={3} width="100%">
              <Divider />
            </Box>
          </>
        )}

        <Box
          p={3}
          display="flex"
          flexGrow={1}
          flexDirection="column"
          style={{ overflowY: 'auto' }}
        >
          <Typography variant="h5">
            <Box color={colors.smoke[900]}>
              <TitleFaq />
            </Box>
          </Typography>
          <Box mt={2}>
            <Typography variant="subtitle2">
              <TextFaq />
            </Typography>
          </Box>
        </Box>

        <Box
          p={3}
          width="100%"
          display="flex"
          flexDirection="row"
          style={{ border: currentPage !== 8 ? '2px solid #F5F8FA' : 'none' }}
        >
          {currentPage !== 0 && currentPage !== 8 && previousButton}
          <Box width="60%">{nextStepButton}</Box>
        </Box>
      </Box>
    </Dialog>
  );
}

OnboardingDialog.propTypes = {
  driverInfo: driverInfoPropType.isRequired
};
