import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography, ThemeProvider } from '@material-ui/core';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';

import colors from '@loggi/mar/src/themes/drivers/colors';

import { ReactComponent as DetailsIcon } from './icons/delivery-not-found-details-icon.svg';
import messages from './constants';

/**
 * This component is based on the new theme for drivers
 * experience, using getThemeForUserType with version v2>
 */
export default function DeliveryNotFoundDetailsPage() {
  const history = useHistory();
  const { dispute } = history.location.state;

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={2}
      >
        <Box
          mt={12}
          display="flex"
          textAlign="center"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <DetailsIcon />
          <Typography
            variant="h6"
            style={{ fontWeight: 'bold', marginTop: 20 }}
          >
            {messages.title}
          </Typography>
          <Box mt={2}>
            <Typography variant="body1">
              {messages.description(dispute?.packageTrackingKey)}
            </Typography>
          </Box>
        </Box>
        <Box width="100%" mt="auto">
          <Box
            p={2}
            borderRadius={8}
            bgcolor={colors.neutrals.shapes.lighter}
            mb={5}
          >
            <Typography
              variant="body2"
              style={{ fontWeight: 'bold', marginBottom: 6 }}
            >
              {messages.warningTitle}
            </Typography>
            <Typography variant="body2">{messages.warningBody}</Typography>
          </Box>
          <Button
            data-testid="back-button"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={handleGoBack}
          >
            {messages.buttonBack}
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
