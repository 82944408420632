import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';

import { ReactComponent as LoggiIcon } from 'view/images/loggi.svg';
import { ReactComponent as ErrorIcon } from 'view/images/error-generic.svg';

import LOADING_ERROR_TEXT from './constants';

export default function RetryPage({ onRetry }) {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        pt={4.5}
        px={2.5}
        pb={2.5}
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <Box>
          <LoggiIcon height="34px" />
        </Box>
      </Box>
      <Box
        py={2.5}
        px={2.5}
        flexGrow={1}
        display="flex"
        flexDirection="column"
        data-testid="loading-error"
      >
        <ErrorIcon />

        <Box pt={2}>
          <Typography variant="h5">{LOADING_ERROR_TEXT.TITLE}</Typography>
        </Box>

        <Box color={colors.smoke[700]}>
          <Typography variant="body1">{LOADING_ERROR_TEXT.SUBTITLE}</Typography>
        </Box>
      </Box>
      <Box pb={2.5} px={2.5} width="100%">
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onRetry}
          data-testid="retryButton"
        >
          {LOADING_ERROR_TEXT.BUTTON}
        </Button>
      </Box>
    </Box>
  );
}

RetryPage.propTypes = {
  onRetry: PropTypes.func.isRequired
};
