import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import TouchAppOutlinedIcon from '@material-ui/icons/TouchAppOutlined';
import { colors } from '@loggi/mar';

import { messages } from './constants';

export default function SignatureOverlay({
  onOverlayClick,
  protocolRecipientName
}) {
  return (
    <Box
      px={3.5}
      height="80%"
      width="100%"
      borderRadius="16px"
      style={{
        backgroundColor: colors.smoke[50],
        color: colors.smoke[500]
      }}
    >
      <Box
        data-testid="buttonTouch"
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ transform: `rotate(90deg)` }}
        onClick={onOverlayClick}
      >
        <Box data-testid="icon-emoji">
          <TouchAppOutlinedIcon aria-label="Hand down" color="primary" />
        </Box>
        <Box>
          <Typography variant="h5" data-testid="driver-distance-alert-template">
            {messages.signature.signatureAreaLabel.replace(
              '%s',
              protocolRecipientName
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

SignatureOverlay.propTypes = {
  onOverlayClick: PropTypes.func.isRequired,
  protocolRecipientName: PropTypes.string.isRequired
};
