import { ReactComponent as AlertIcon } from 'view/images/ic-alert.svg';
import { ReactComponent as CheckIcon } from 'view/images/ic-check.svg';
import { ReactComponent as ErrorIcon } from 'view/images/ic-error.svg';
import { ReactComponent as InfoIcon } from 'view/images/ic-info.svg';

export const textButton = 'Beleza';

export const variantIcon = {
  success: CheckIcon,
  warning: AlertIcon,
  error: ErrorIcon,
  info: InfoIcon
};
