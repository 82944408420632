import { getParameter } from './url-parser';

const currentUserKey = 'current_user';

export const getCurrentUser = () => {
  const currentUser = localStorage.getItem(currentUserKey);
  return JSON.parse(currentUser) || {};
};

export const storeCurrentUser = currentUser => {
  localStorage.setItem(currentUserKey, JSON.stringify(currentUser));
};

export const removeCurrentUser = () => {
  localStorage.removeItem(currentUserKey);
};

export const getCredentials = () => window?.driverAppBridge?.getLWApiKey?.();

export const isFsCognitoAuth = () =>
  window?.driverAppBridge?.isFsCognitoAuth?.();

export const isFsCognitoSmsAuth = () =>
  window?.driverAppBridge?.isFsCognitoSmsAuth?.();

/**
 * Verify if driver is authenticated via ApiKey
 *
 * @returns {boolean}
 */
export const isAuthenticatedWithApiKey = () => !!getCredentials();

/**
 * Verify if driver is authenticated via Amazon Cognito
 *
 * @returns {boolean}
 */
export const isAuthenticatedWithCognito = user => {
  try {
    return !Number.isNaN(JSON.parse(user['custom:access']).loggi_user_id);
  } catch {
    return false;
  }
};

export const getEmailFromCredentials = () => {
  const currentUserEmail = getCurrentUser()?.email;
  if (currentUserEmail) {
    return currentUserEmail;
  }

  const credentials = getCredentials();
  const email = credentials?.match(/(?<=\s)((.*)(?=:))/) || ['']; // NOSONAR
  return email[0];
};

export const isSupport = user => {
  const email = getParameter('email');
  const apikey = getParameter('key');
  return user?.['cognito:groups'].includes('support') || (email && apikey);
};

const isAuthenticatedWithCognitoOrApiKey = user => {
  return isAuthenticatedWithCognito(user) || isAuthenticatedWithApiKey();
};

const impersonateWithApiKey = 'representar_apikey';
/**
 * Get ApiKey from auth query string
 *
 * The ApiKey is composite by driver's email and api_key and is provided by DriverApp as an URL parameter with below pattern:
 *
 *    ApiKey driver_email:driver_api_key
 *
 * Example of a provided URL:
 *
 *    https://drivers.loggi.com/representar-apikey?email=email@loggi.com&key=fd188cb85441d7bf136d195587094a6ff0376add
 *
 * @returns {string} ApiKey
 */
export function getImpersonateWithApiKey() {
  const auth = localStorage.getItem(impersonateWithApiKey);
  return JSON.parse(auth) || undefined;
}

export const storeImpersonateWithApiKey = auth => {
  localStorage.setItem(impersonateWithApiKey, JSON.stringify(auth));
};

export const logoutAndPrepareNewSession = () => {
  localStorage.clear();
  window.driverAppBridge?.logoutAndPrepareNewSession?.();
};

export default isAuthenticatedWithCognitoOrApiKey;
