import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { Box, Grid, Typography } from '@material-ui/core';
import { colors } from '@loggi/mar';
import { useFeatureSwitch } from '@loggi/firebase-feature-switches';
import featureSwitches from 'operations/feature-switches';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { driverInfoPropType } from 'shared/prop-type/index';
import { isFsCognitoSmsAuth as isFsCognitoSmsAuthEnabled } from 'operations/auth/credentials';
import { menuConfigError, getMenuItem } from './constants';
import { linkToSuport } from '../constants';

export default function MenuContainer({ showErrorConfigMenu, driverInfo }) {
  const history = useHistory();
  const isCognitoSmsAuthEnabled = isFsCognitoSmsAuthEnabled();
  const directPickupThroughtDrivers = useFeatureSwitch(
    featureSwitches.directPickupThroughtDrivers
  );

  const { signOut: cognitoSignOut } = useAmplifyAuth();
  const { location } = window;

  const newsUrl = useFeatureSwitch(featureSwitches.linkToNewsUrl);

  const onOpenSupport = event => {
    event.preventDefault();
    window.open(linkToSuport);
  };

  return (
    <>
      {showErrorConfigMenu && (
        <Box component="a" onClick={onOpenSupport}>
          <Alert severity="error">{menuConfigError}</Alert>
        </Box>
      )}
      <Box mt={2} lineHeight="18px" style={{ overflowX: 'auto' }}>
        <Grid container style={{ width: 'max-content' }}>
          {driverInfo?.menu
            .map(menu =>
              getMenuItem(menu, {
                driver: driverInfo,
                history,
                location,
                directPickupThroughtDrivers,
                isCognitoSmsAuthEnabled,
                cognitoSignOut,
                linkToNewsUrl: newsUrl
              })
            )
            .filter(item => !!item)
            .map(item => (
              <Box
                p={1.5}
                mr={1.5}
                style={item.style || { cursor: 'pointer' }}
                bgcolor={item.bgcolor || colors.smoke[50]}
                color={item.color || colors.smoke[900]}
                height={100}
                width={100}
                borderRadius={8}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                key={item.text}
                onClick={() =>
                  item.action({
                    history,
                    location,
                    isCognitoSmsAuthEnabled,
                    cognitoSignOut
                  })
                }
                data-testid="menu-item"
              >
                <item.icon height="24px" />
                <Typography>{item.text}</Typography>
              </Box>
            ))}
        </Grid>
      </Box>
    </>
  );
}

MenuContainer.propTypes = {
  showErrorConfigMenu: PropTypes.bool,
  driverInfo: driverInfoPropType
};

MenuContainer.defaultProps = {
  showErrorConfigMenu: false,
  driverInfo: {}
};
