import React, { useState } from 'react';
import { bool } from 'prop-types';
import { Button, Box, Typography, Drawer } from '@material-ui/core';

import { ReactComponent as DrawerTopIcon } from 'view/images/drawer_top.svg';
import { ReactComponent as NewDriverAppVersionIcon } from 'view/images/new_driver_app_version.svg';

import { handleOpenPlayStore } from './index';

export const messages = {
  title: 'Nova versão disponível',
  subtitle:
    'Uma nova versão do aplicativo está disponível, você pode atualizar agora tocando no botão abaixo.',
  updateButton: 'Atualizar agora',
  remindMeLaterButton: 'Lembre-me mais tarde'
};

export default function NewDriverAppVersionDrawer({ hasNewDriverAppVersion }) {
  const [open, setOpen] = useState(hasNewDriverAppVersion);
  const handleClose = () => setOpen(false);
  const handleUpdateButton = event => {
    handleOpenPlayStore(event);
    setOpen(false);
  };
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        style: { borderTopRightRadius: '16px', borderTopLeftRadius: '16px' }
      }}
    >
      {open && (
        <Box px={3} pb={3}>
          <Box pt={2} pb={3} display="flex" justifyContent="center">
            <DrawerTopIcon />
          </Box>
          <Box pt={3.5} pb={2}>
            <NewDriverAppVersionIcon />
          </Box>

          <Typography variant="h6">
            <Box component="span" fontWeight={600}>
              {messages.title}
            </Box>
          </Typography>
          <Box pt={2}>
            <Typography variant="body1">{messages.subtitle}</Typography>
          </Box>

          <Box pt={5}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleUpdateButton}
            >
              {messages.updateButton}
            </Button>
          </Box>
          <Box pt={1}>
            <Button
              variant="text"
              color="primary"
              size="large"
              fullWidth
              onClick={handleClose}
            >
              {messages.remindMeLaterButton}
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

NewDriverAppVersionDrawer.propTypes = {
  hasNewDriverAppVersion: bool.isRequired
};
