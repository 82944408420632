import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@loggi/mar';
import { Box, Typography } from '@material-ui/core';
import { ReactComponent as MapRepresentation } from './images/map_point.svg';

import { messages } from './constants';

export default function Proximity({ distance }) {
  return (
    <Box px={2.5} bgcolor={colors.root[0]}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <MapRepresentation />
      </Box>
      <Box mb={1} display="flex" flexDirection="column" alignItems="center" />
      <Box textAlign="center">
        <Box>
          <Typography variant="h6">
            {messages.proximity.youAre}{' '}
            <span style={{ color: colors.blue[500] }}>
              {distance} {messages.proximity.meters}
            </span>{' '}
            {messages.proximity.deliveryPlace}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography data-testid="driver-distance-alert-template">
            {messages.proximity.alertDescription}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

Proximity.propTypes = {
  distance: PropTypes.number.isRequired
};
