import lwApi from 'operations/config/api';

export const graphqlUrl = '/graphql/';

export const updateDriverAvailabilityMutation = `
  mutation UpdateDriverAvailability($driverId: Int!, $available: Boolean!) {
    updateDriverAvailability(input: {
        driverId: $driverId,
        available: $available,
    }) {
        isSuccessfully: success
        errors {
            message
        }
    }
  }
`;

export default function updateDriverAvailabilityRequest(variables) {
  return lwApi()
    .url(graphqlUrl)
    .post(
      JSON.stringify({
        query: updateDriverAvailabilityMutation,
        variables
      })
    )
    .json()
    .then(response => {
      const {
        data: {
          updateDriverAvailability: { errors, isSuccessfully }
        }
      } = response;
      if (isSuccessfully) {
        return isSuccessfully;
      }

      const errorResponse = { errors };

      return Promise.reject(errorResponse);
    });
}
