export const analyticsCategories = {
  newHome: 'NewHome',
  disputeDetails: 'DisputeDetails',
  disputeDetailsRecipient: 'DisputeDetailsRecipient',
  disputeEvidences: 'DisputeEvidences',
  disputeFeedback: 'DisputeFeedback',
  disputeList: 'DisputeList'
};

export const analyticsEventNames = {
  clickBackButton: 'click_back_button',
  clickDisputeButtonBackToDeliveriesList: 'click_button_voltar_para_entregas',
  clickDisputeButtonConfirmDelivery: 'click_button_confirmar_entrega',
  clickDisputeButtonDeliveryDone: 'click_button_fiz_entrega',
  clickDisputeButtonGoThere: 'click_button_ir_pra_la',
  clickDisputeButtonConfirmAnyway: 'click_button_confirmar_mesmo_assim',
  clickDisputeDetails: 'click_dispute_details',
  hasDisputes: 'has_disputes',
  viewBannerDispute: 'view_banner_dispute',
  clickBannerDispute: 'click_banner_dispute',
  clickButtonFindOffers: 'click_button_buscar_ofertas',
  home: 'home_screen',
  viewPermissionNotification: 'view_permission_notification',
  allowPermissionNotification: 'allow_permission_notification',
  notAllowPermissionNotification: 'not_allow_permission_notification'
};
