import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { messages } from './constants';
import { ReactComponent as EmptyIcon } from './images/empty-state-search.svg';

export default function EmptyState() {
  return (
    <Box
      flexGrow={1}
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      p={5}
    >
      <Box mt={10} mb={7}>
        <EmptyIcon />
      </Box>
      <Box mb={7}>
        <Typography variant="h5">{messages.empty.title}</Typography>
      </Box>
      <Box>
        <Typography variant="body2">{messages.empty.description}</Typography>
      </Box>
    </Box>
  );
}
