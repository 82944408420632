import React from 'react';
import { string, func } from 'prop-types';
import { Button, Box, Typography, Drawer } from '@material-ui/core';
import { colors } from '@loggi/mar';

import { ReactComponent as EnableNotificationsIcon } from 'view/images/enable_notifications.svg';

export const STATE_ENUM = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
  isOpen: state => state !== STATE_ENUM.CLOSED
};

export const messages = {
  title: 'Você ativou o modo silencioso.',
  textNoNotification:
    'Enquanto o app estiver silenciado, você não vai receber notificações de oferta da Loggi. Se quiser, você ainda pode aceitar ofertas disponíveis na lista.',
  textHowToEnable:
    'Para o app voltar a tocar, é só desativar o modo silencioso. 😄',
  button: 'Beleza, entendi!'
};

export default function DisableNotificationDrawer({
  drawerState,
  setDrawerState,
  buttonClick
}) {
  return (
    <Drawer
      anchor="bottom"
      open={STATE_ENUM.isOpen(drawerState)}
      onClose={() => setDrawerState(STATE_ENUM.CLOSED)}
      PaperProps={{
        elevation: 0,
        style: { borderTopRightRadius: '16px', borderTopLeftRadius: '16px' }
      }}
    >
      {STATE_ENUM.isOpen(drawerState) && (
        <Box px={2.5} pb={2.5} color={colors.smoke[900]}>
          <Box pt={1.5}>
            <Box
              bgcolor={colors.blue[50]}
              style={{ borderRadius: '100px' }}
              width={56}
              height={56}
              p={2}
            >
              <EnableNotificationsIcon />
            </Box>
          </Box>

          <Typography variant="h5">
            <Box component="span" fontWeight="fontWeightBold">
              {messages.title}
            </Box>
          </Typography>
          <Box pt={1} color={colors.smoke[800]}>
            {messages.textNoNotification}
            <Box pt={5.5}>{messages.textHowToEnable}</Box>
          </Box>

          <Box pt={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={buttonClick}
            >
              {messages.button}
            </Button>
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

DisableNotificationDrawer.propTypes = {
  drawerState: string.isRequired,
  setDrawerState: func.isRequired,
  buttonClick: func.isRequired
};
