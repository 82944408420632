import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ReactComponent as LoggiIcon } from 'view/images/loggi.svg';

export default function HomeSkeleton() {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      data-testid="skeleton-loading"
    >
      <Box
        pt={4.5}
        px={2.5}
        pb={2.5}
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <Grid container justify="space-between">
          <LoggiIcon height="34px" />

          <Skeleton width="64px" />
        </Grid>
        <Box mt={4}>
          <Typography variant="h4">
            <Skeleton />
          </Typography>

          <Typography variant="h3">
            <Skeleton />
          </Typography>

          <Box>
            <Skeleton />
          </Box>
        </Box>
        <Box my={2.5} lineHeight="18px">
          <Skeleton height="100px" />
        </Box>
      </Box>
      <Box
        pb={2.5}
        px={2.5}
        flexGrow={1}
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        display="flex"
        flexDirection="column"
      >
        <Skeleton height="60px" width="200px" />
        <Typography variant="subtitle2">
          <Skeleton width="200px" />
        </Typography>
        <Typography variant="subtitle2">
          <Skeleton width="200px" />
        </Typography>
      </Box>
      <Box pb={2.5} px={2.5} width="100%">
        <Skeleton height="56px" />
      </Box>
    </Box>
  );
}
