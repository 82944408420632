import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Dialog,
  Button,
  IconButton,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { driverInfoPropType } from 'shared/prop-type/index';
import { ReactComponent as LoggiIcon } from 'view/images/loggi.svg';
import { useHistory } from 'react-router';
import { PAGES } from 'app';
import { linkToSuport, linkToRegisterHelper } from 'view/pages/home/constants';
import { ErrorCriticalPage } from '@loggi/driver-signup';
import { messageErrorRegistrationDialog } from './constants';

const ErrorDialog = ({ canClose, button, handleButtonClick, pageMessage }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const handleClose = () => setIsDialogOpen(false);

  return (
    <Dialog
      fullScreen
      open={isDialogOpen}
      data-testid="dialog-error-registration"
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          pt={4.5}
          px={3.5}
          pb={2.5}
          width="100%"
          display="flex"
          flexDirection="column"
        >
          <Grid container justify="space-between" alignItems="center">
            <LoggiIcon height="34px" />

            {canClose && (
              <IconButton
                data-testid="errorRegistrationDialogCloseButon"
                onClick={handleClose}
              >
                <CloseIcon color="primary" />
              </IconButton>
            )}
          </Grid>
        </Box>
        <Box pt={3} px={2.5} flexGrow={1}>
          {pageMessage}
        </Box>
        <Box p={3} width="100%">
          <Button
            data-testid="errorDialogButon"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={handleButtonClick}
          >
            {button}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  canClose: PropTypes.bool.isRequired,
  button: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  pageMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired
};

export default function ErrorRegistrationDialog({
  driverInfo,
  shouldOpenErrorRegistrationDialog
}) {
  const history = useHistory();

  const handleOpenDriverSignup = event => {
    event.preventDefault();
    history.push({
      pathname: PAGES.DRIVER_SIGN_UP,
      search: window.location.search
    });
  };

  const handleContactLoggiRegisterHelper = event => {
    event.preventDefault();
    window.open(linkToRegisterHelper);
  };

  const handleContactLoggi = event => {
    event.preventDefault();
    window.open(linkToSuport);
  };

  if (
    driverInfo?.operationalStatus === undefined ||
    driverInfo?.onlySupport ||
    !shouldOpenErrorRegistrationDialog
  ) {
    return <></>;
  }

  const { firstName, operationalStatus, hasDocumentBlock } = driverInfo;

  if (hasDocumentBlock) {
    const buttonText = 'Ajuda';
    const canClose = false;

    return (
      <ErrorDialog
        canClose={canClose}
        button={buttonText}
        handleButtonClick={handleContactLoggiRegisterHelper}
        pageMessage={<ErrorCriticalPage firstName={firstName} />}
      />
    );
  }

  const {
    title,
    subtitle,
    button,
    Icon,
    canClose,
    contactLoggi
  } = messageErrorRegistrationDialog(operationalStatus, firstName);

  return (
    <ErrorDialog
      canClose={canClose}
      button={button}
      handleButtonClick={
        contactLoggi ? handleContactLoggi : handleOpenDriverSignup
      }
      pageMessage={
        <>
          <Box pt={6} width="100%">
            <Icon data-testid="errorRegistrationIcon" />
          </Box>
          <Typography variant="h5">{title}</Typography>
          <Box>{subtitle}</Box>
        </>
      }
    />
  );
}

ErrorRegistrationDialog.propTypes = {
  driverInfo: driverInfoPropType.isRequired,
  shouldOpenErrorRegistrationDialog: PropTypes.bool.isRequired
};
