import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  getThemeForUserType,
  availableThemeVersions,
  userTypeEnum
} from '@loggi/mar';
import { Box, Button, Typography } from '@material-ui/core';
import { ReactComponent as PermissionIcon } from 'view/images/permission_icon.svg';

const messages = {
  title: 'Não perca ofertas',
  subtitle:
    'Os entregadores que permitem notificações têm mais chances de ver boas ofertas em tempo real!',
  beginMessage: 'Clique em',
  highlightedMessage: ' ver permissões ',
  endMessage:
    ' para atualizar as configurações do seu aplicativo e permitir o recebimento das mensagens!',
  buttonAllow: 'Ver permissões',
  buttonNotAllow: 'Agora não'
};

export default function PermissionNotification({
  handleOnAllowClick,
  handleOnNotAllowClick
}) {
  return (
    <ThemeProvider
      theme={getThemeForUserType({
        userType: userTypeEnum.drivers,
        version: availableThemeVersions.drivers.v2
      })}
    >
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          pt={3}
          px={2.5}
          flexGrow={1}
          data-testid="permission-notification-message"
        >
          <Box pt={6} pb={5} width="100%">
            <PermissionIcon width="116px" height="136px" />
          </Box>
          <Typography style={{ fontWeight: 'bold' }} variant="h5" gutterBottom>
            {messages.title}
          </Typography>
          <Typography paragraph>{messages.subtitle}</Typography>
          <Typography>
            {messages.beginMessage}
            <span style={{ fontWeight: 'bold' }}>
              {messages.highlightedMessage}
            </span>
            {messages.endMessage}
          </Typography>
        </Box>
        <Box
          p={3}
          width="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box width="35%" mr={2}>
            <Button
              fullWidth
              color="primary"
              size="large"
              variant="outlined"
              onClick={handleOnNotAllowClick}
              data-testid="button-not-allow-permission"
            >
              {messages.buttonNotAllow}
            </Button>
          </Box>
          <Box width="65%">
            <Button
              fullWidth
              color="primary"
              size="large"
              variant="contained"
              onClick={handleOnAllowClick}
              data-testid="button-allow-permission"
            >
              {messages.buttonAllow}
            </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

PermissionNotification.propTypes = {
  handleOnAllowClick: PropTypes.func.isRequired,
  handleOnNotAllowClick: PropTypes.func.isRequired
};
