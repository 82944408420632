import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Snackbar, SnackbarContent, Button, Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { variantIcon, textButton } from './constants';

const SnackbarMessage = forwardRef((props, ref) => {
  const { id, open, message, variant, withButton } = props;
  const { closeSnackbar } = useSnackbar();
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      ref={ref}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={7000}
      style={{
        position: 'relative',
        maxWidth: '550px'
      }}
      onClose={() => closeSnackbar(id)}
    >
      <SnackbarContent
        message={
          <Box display="flex" alignItems="center">
            <Icon />
            <Box
              ml={2}
              data-testid="snackbarContent"
              style={{
                maxWidth: '270px'
              }}
            >
              {message}
            </Box>
          </Box>
        }
        action={
          withButton && [
            <Button
              variant="outlined"
              key="close"
              aria-label="close"
              color="primary"
              onClick={() => closeSnackbar(id)}
              size="small"
            >
              {textButton}
            </Button>
          ]
        }
      />
    </Snackbar>
  );
});

SnackbarMessage.propTypes = {
  id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.string,
  withButton: PropTypes.bool
};

SnackbarMessage.defaultProps = {
  variant: 'error',
  withButton: false
};

export default SnackbarMessage;
