import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid } from '@material-ui/core';

import { messages } from './constants';

export default function SignatureControls({
  onRedo,
  onContinue,
  disabled,
  isLoading
}) {
  return (
    <Box px={3.5} py={2.5}>
      <Grid container spacing={1} wrap="nowrap">
        <Grid item xs>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            fullWidth
            disabled={disabled}
            onClick={onRedo}
            data-testid="redo-button"
          >
            {messages.signature.buttonRedoSignature}
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={disabled}
            onClick={onContinue}
          >
            {(isLoading && messages.confirm.sendingText) ||
              messages.signature.buttonSetState}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

SignatureControls.propTypes = {
  onRedo: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};
